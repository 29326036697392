import { Link, Navigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import OnboardingFlow from "../views/OnboardingFlow";
import classNames from "classnames";
import ResponsiveVariables from "../utils/Global";
import ClientLogo from "../components/systemGeneralComponents/clientLogo.js";
import useAxios from "../utils/useAxios";

function PrivateRoute({
  isOnboardingComplete,
  setIsOnboardingComplete,
  children,
}) {
  const auth = useContext(AuthContext);

  console.log(auth);
  if (!auth.authTokens) {
    return <Navigate to="/" />;
  }

  if (!isOnboardingComplete) {
    console.log("Rendering!");
    return (
      <div
        className={classNames(ResponsiveVariables.screenClass, "navandbody")}
      >
        {/* <Header /> */}
        <div
          className={classNames(ResponsiveVariables.screenClass, "title-bar")}
        >
          <Link to="/" className="nav-item-tilte-bar-logo">
            <ClientLogo width="40px" height="40px" display="flex" />
            <p>QUORUM</p>
          </Link>
        </div>
        <OnboardingFlow setIsOnboardingComplete={setIsOnboardingComplete} />
      </div>
    );
  }

  return children;
}

export default PrivateRoute;
