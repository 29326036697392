import { useEffect, useState } from "react";
import useAxios from "../utils/useAxios";
import { useForm } from "react-hook-form";
import "./UserDetailEditModal.css";
import Modal from "@mui/material/Modal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";

import {
  compareTwoDatesEqual,
  PastStartDate,
  formatDate,
  formatTime,
} from "../utils/DateFunctions";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

const UserDetailEditModal = ({
  showModal,
  setShowModal,
  data,
  email,
  id,
  fetchData,
}) => {
  const api = useAxios();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    emailAddress: "",
    tel: "",
    street: "",
    streetNumber: "",
    zipCode: "",
    place: "",
    dateofBirth: "",
    language: "",
    function: "",
    admin: "",
  });

  const handleClose = () => {
    console.log("closing!");
    setShowModal(false);
  };

  const onSubmit = (event) => {
    updateUser();
    setShowModal(false);
  };

  const updateUser = async () => {
    try {
      const response = await api.put("/user/" + id + "/", formData);
      console.log("TEST");
      fetchData();
    } catch {
      console.log("Something went wrong while retreiving user");
    }
  };

  const handleFirstNameChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      name: event.target.value,
    }));
  };

  const handleLastNameChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      surname: event.target.value,
    }));
  };

  const handleEmailChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      emailAddress: event.target.value,
    }));
  };
  const handleTelChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      tel: event.target.value,
    }));
  };
  const handleStreetChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      street: event.target.value,
    }));
  };
  const handleStreetnrChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      streetNumber: event.target.value,
    }));
  };
  const handleZipChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      zipCode: event.target.value,
    }));
  };
  const handlePlaceChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      place: event.target.value,
    }));
  };
  /*  const handleDOBChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      dateofBirth: event.target.value,
    }));
  }; */
  const handleDateOfBirthChange = (event) => {
    console.log("dit is de date of birth:");
    console.log(event);
    setFormData((prevFormData) => ({
      ...prevFormData,
      dateofBirth: event.format("YYYY-MM-DD"),
    }));
  };
  const handleProfessionChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      function: event.target.value,
    }));
  };
  const handleLanguageChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      language: event.target.value,
    }));
  };
  const handleRoleChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      admin: event.target.value,
    }));
  };

  useEffect(() => {
    console.log("filling in formData based on data!");
    setFormData((prevFormData) => ({
      ...prevFormData,
      name: data.name,
      surname: data.surname,
      emailAddress: email,
      tel: data.tel,
      street: data.street,
      streetNumber: data.streetNumber,
      zipCode: data.zipCode,
      place: data.place,
      dateofBirth: data.dateofBirth,
      function: data.function,
      language: data.language,
      admin: data.admin,
    }));
  }, [data]);

  return (
    showModal && (
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classNames(
          ResponsiveVariables.screenClass,
          "edit-user-modal"
        )}
      >
        <div
          className={classNames(
            ResponsiveVariables.screenClass,
            "edit-user-modal-body"
          )}
        >
          <div className={classNames(ResponsiveVariables.screenClass, "title")}>
            <h1>Edit profile</h1>
          </div>
          <br></br>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classNames(
              ResponsiveVariables.screenClass,
              "edit-profile-form"
            )}
            noValidate
          >
            <div className="form-group">
              <label htmlFor="firstname">First Name </label>
              <input
                {...register("firstname", {
                  required: "This field is required",
                  minLength: { value: 2, message: "Min length needs to be 2" },
                  maxLength: { value: 64, message: "Max length is 64!" },
                })}
                className="form-control"
                id="firstname"
                placeholder="First Name"
                value={formData.name}
                onChange={handleFirstNameChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.firstname && errors.firstname.message}
            </p>
            <div className="form-group">
              <label htmlFor="lastname">Last Name </label>
              <input
                {...register("lastname", {
                  required: "This field is required",
                  minLength: { value: 2, message: "Min length needs to be 2" },
                  maxLength: { value: 64, message: "Max length is 64!" },
                })}
                className="form-control"
                id="lastname"
                placeholder="Last Name"
                value={formData.surname}
                onChange={handleLastNameChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.lastname && errors.lastname.message}
            </p>
            <div className="form-group">
              <label htmlFor="email">E-mail </label>
              <input
                {...register("email", {
                  required: "This field is required",
                  minLength: { value: 5, message: "Min length needs to be 5" },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Email is not valid",
                  },
                })}
                className="form-control"
                id="email"
                placeholder="E-mail"
                value={formData.emailAddress}
                onChange={handleEmailChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.email && errors.email.message}
            </p>
            <div className="form-group">
              <label htmlFor="tel">Tel </label>
              <input
                {...register("tel", {
                  required: "This field is required",
                  minLength: { value: 2, message: "Min length needs to be 2!" },
                  maxLength: { value: 20, message: "Max length is 20!" },
                  pattern: {
                    value:
                      /^[+]?[(]?([0-9]\s*){3}[)]?[-\s.]?[0-9]{3}[-\s.]?([0-9]\s*){4,6}$/,
                    message: "Phone number is not correct",
                    value: /^\+/,
                    message: "Your phone number should start with a +",
                  },
                })}
                className="form-control"
                id="tel"
                placeholder="Tel (+32 471 47 28 37)"
                value={formData.tel}
                onChange={handleTelChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.tel && errors.tel.message}
            </p>
            <div className="form-group">
              <label htmlFor="street">Street </label>
              <input
                {...register("street", {
                  required: "This field is required",
                  minLength: { value: 2, message: "Min length needs to be 2" },
                  maxLength: { value: 64, message: "Max length is 64!" },
                })}
                className="form-control"
                id="street"
                placeholder="Street"
                value={formData.street}
                onChange={handleStreetChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.street && errors.street.message}
            </p>
            <div className="form-group">
              <label htmlFor="streetnr">Streetnumber </label>
              <input
                {...register("streetnr", {
                  required: "This field is required",
                  minLength: { value: 1, message: "Min length needs to be 1" },
                  maxLength: { value: 10, message: "Max length is 10!" },
                })}
                className="form-control"
                id="streetnr"
                placeholder="Streetnumber"
                value={formData.streetNumber}
                onChange={handleStreetnrChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.streetnr && errors.streetnr.message}
            </p>
            <div className="form-group">
              <label htmlFor="zip">Postal code </label>
              <input
                {...register("zip", {
                  required: "This field is required",
                  minLength: { value: 2, message: "Min length needs to be 2" },
                  maxLength: { value: 16, message: "Max length is 16!" },
                })}
                className="form-control"
                id="zip"
                placeholder="Postal code"
                value={formData.zipCode}
                onChange={handleZipChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.zip && errors.zip.message}
            </p>
            <div className="form-group">
              <label htmlFor="place">City </label>
              <input
                {...register("place", {
                  required: "This field is required",
                  minLength: { value: 2, message: "Min length needs to be 2" },
                  maxLength: { value: 64, message: "Max length is 64!" },
                })}
                className="form-control"
                id="place"
                placeholder="City"
                value={formData.place}
                onChange={handlePlaceChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.place && errors.place.message}
            </p>
            {/* <div className="form-group">
              <label htmlFor="dob">Date of birth </label>
              <input
                {...register("dateOfBirth", {
                  valueAsDate: true,
                  required: "This field is required",
                  minLength: { value: 2, message: "Min length needs to be 2" },
                })}
                className="form-control"
                type="date"
                id="dateOfBirth"
                placeholder="Date of birth"
                /* value={formatDate(
                  formData.dateofBirth,
                  "numeric",
                  "numeric",
                  "numeric"
                )} 
                value={formData.dateofBirth}
                onChange={handleDOBChange}
              />
            </div> */}
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Date of birth"
                  value={new Date(formData.dateofBirth)}
                  inputFormat="DD-MM-YYYY"
                  onChange={handleDateOfBirthChange}
                  ampm={false}
                  //views={["day", "month", "year"]}
                  // views={["day", "hours", "minutes"]}
                />
              </LocalizationProvider>
              <input
                {...register("dateOfBirth", {
                  required: "This field is required",
                })}
                id="dateOfBirth"
                hidden={true}
                value={formData.dateofBirth}
                className="form-control"
                type="date"
              />
            </div>
            <p className="form-validation-error">
              {errors.dateofBirth && errors.dateofBirth.message}
            </p>
            <div className="form-group">
              <label htmlFor="profession">Profession </label>
              <input
                {...register("profession", {
                  required: "This field is required",
                  minLength: { value: 2, message: "Min length needs to be 2" },
                  maxLength: { value: 64, message: "Max length is 64!" },
                })}
                className="form-control"
                id="profession"
                placeholder="Profession"
                value={formData.function}
                onChange={handleProfessionChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.profession && errors.profession.message}
            </p>
            <div className="form-group">
              <div className="pair">
                <label htmlFor="language">Language </label>
                <select
                  {...register("language", {
                    required: "This field is required",
                    minLength: {
                      value: 2,
                      message: "Min length needs to be 2",
                    },
                    maxLength: { value: 32, message: "Max length is 32!" },
                  })}
                  className="form-control selectlanguage"
                  id="language"
                  placeholder="Language"
                  value={formData.language}
                  onChange={handleLanguageChange}
                >
                  <option value="nl">NL</option>
                  <option value="en">ENG</option>
                  <option value="fr">FR</option>
                </select>
              </div>
            </div>
            {/* <div className="form-group">
              <label htmlFor="admin">Role </label>
              <input
                {...register("admin", {
                  required: "This field is required",
                  minLength: { value: 2, message: "Min length needs to be 2" },
                  maxLength: { value: 64, message: "Max length is 64!" },
                })}
                className="form-control"
                id="admin"
                placeholder="Role"
                value={formData.admin ? "Admin" : "Member"}
                // value={formData.admin}
                onChange={handleRoleChange}
              />
            </div> */}

            <div className="form-buttons">
              <button className="form-control btn btn-primary" type="submit">
                Save
              </button>
              <button
                className="form-control btn btn-secundary cancelbutton"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    )
  );
};

export default UserDetailEditModal;
