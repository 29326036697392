import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAxios from "../utils/useAxios";
import { RegisterModal } from "../components/RegisterModal";
import { ReminderModal } from "../components/ReminderModal";
import { ICalEvent } from "../components/ICalEvent";
import CalendarIcon from "react-calendar-icon";
import ReactGA from "react-ga4";

import {
  compareTwoDatesEqual,
  PastStartDate,
  formatDate,
  formatTime,
} from "../utils/DateFunctions";

import ActivityDetailEditModal from "./ActivityDetailEditModal";
import ActivityDetailDeleteModal from "./ActivityDetailDeleteModal";
import AttendeeList from "../components/AttendeeList";

import "./activityDetailPage.css";
import "../components/AttendeeList.css";

// import BeerAndPizza from "../img/temp/beerandpizza.jpeg";
import BeerAndPizza from "../img/systemGeneral/upcomingevent.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

const editIcon = <FontAwesomeIcon icon={faPencil} className="edit-icon" />;
const backIcon = <FontAwesomeIcon icon={faChevronLeft} className="back-icon" />;
const deleteIcon = (
  <FontAwesomeIcon icon={faTrashCan} className="delete-icon" />
);

function ActivityDetailPage() {
  ReactGA.send({
    hitType: "pageview",
    page: "/activitydetail",
    title: "Activity Detail",
  });

  const { id } = useParams();
  const [res, setRes] = useState("");
  const api = useAxios();
  const navigate = useNavigate();
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalReminder, setShowModalReminder] = useState(false);

  const [registerStatus, setRegisterStatus] = useState("R"); //R(egistered) or A(ccepted) or D(eclined)
  const [remark, setRemark] = useState("");
  const [form_answers, setFormAnswers] = useState("{form: []}");
  const [isAdmin, setIsAdmin] = useState(false);

  const [editActivityModalState, setEditActivityModalState] = useState(false);
  const [deleteActivityModalState, setDeleteActivityModalState] =
    useState(false);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (timeString) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(timeString).toLocaleTimeString(undefined, options);
  };

  const openModalRegister = () => {
    console.log(res.start_date);
    console.log(new Date());
    if (new Date() < new Date(res.start_date)) {
      setShowModalRegister((showModalRegister) => !showModalRegister);
    }
  };

  const openModalReminder = () => {
    console.log(res.start_date);
    console.log(new Date());
    if (new Date() < new Date(res.start_date)) {
      setShowModalReminder((showModalReminder) => !showModalReminder);
    }
  };

  const sendReminder = async () => {
    try {
      const response = await api.get("/activity/" + id + "/sendreminder");
      console.log(response.data);
    } catch {
      console.log("Something went wrong while sending reminder");
    }
  };

  const deleteActivity = async () => {
    try {
      const response = await api.delete("/activity/" + id + "/");
      console.log(response.data);
      navigate("/activities");
    } catch {
      console.log("Something went wrong while deleting activity");
    }
  };

  /*  const openModalRegister = () => {
    console.log(res.start_date);
    console.log(new Date());
    if (new Date() < new Date(res.start_date)) {
      setShowModalRegister((showModalRegister) => !showModalRegister);
    }
  }; */

  const registerActivity = async (status) => {
    console.log("Registring activity!");

    let json = {
      activity: id,
      status: status,
      remark: remark,
      form_answers: form_answers,
    };
    console.log(json);
    let url = window._env_.REACT_APP_API_URL + "/api/registeractivity/";
    api
      .post(url, json)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
    fetchID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    try {
      console.log("FETCHING DATA");
      const response = await api.get("/activity/" + id + "/");
      setRes(response.data.response);
      console.log(response.data.response);
      let json = response.data.response.form;
      for (let i = 0; i < json.form.length; i++) {
        json.form[i].answer = "";
      }
      setFormAnswers(json);
    } catch {
      console.log("Something went wrong while retreiving activity");
    }

    try {
      const response = await api.get("/registeractivity/" + id + "/");
      setRegisterStatus(response.data.status);
      setRemark(response.data.remark);
      console.log(response.data.status);
    } catch {
      console.log("Something went wrong retrieving registration status");
    }
  };

  const fetchID = async () => {
    try {
      const response = await api.get("/profile/id/");
      setIsAdmin(response.data.admin);
    } catch {
      console.log("Something went wrong while retreiving id");
    }
  };

  const buttonOutput = () => {
    if (new Date() > new Date(res.end_date)) {
      return (
        <button className="closedbutton" type="button">
          Registration closed
        </button>
      );
    }
    if (registerStatus === "R") {
      return (
        <button
          onClick={openModalRegister}
          className="registreerbutton"
          type="button"
        >
          → Register
        </button>
      );
    }
    if (registerStatus === "A") {
      return (
        <button
          onClick={openModalRegister}
          className="acceptedbutton"
          type="button"
        >
          Accepted
        </button>
      );
    }
    if (registerStatus === "D") {
      return (
        <button
          onClick={openModalRegister}
          className="declinedbutton"
          type="button"
        >
          Declined
        </button>
      );
    }
  };

  const openEditActivityModal = () => {
    setEditActivityModalState(
      (editActivityModalState) => !editActivityModalState
    );
  };

  const openDeleteActivityModal = () => {
    setDeleteActivityModalState(
      (deleteActivityModalState) => !deleteActivityModalState
    );
  };

  return (
    <div className="parentactivitydetailpage">
      <div
        className={classNames(
          ResponsiveVariables.screenClass,
          "activityDetailPage"
        )}
      >
        <div
          className={classNames(
            ResponsiveVariables.screenClass,
            "ADP_TitlePicture"
          )}
        >
          <button
            className={classNames(
              ResponsiveVariables.screenClass,
              "back-button"
            )}
            onClick={() => navigate(-1)}
          >
            {backIcon}
          </button>
          <div
            className={classNames(
              ResponsiveVariables.screenClass,
              "calendaricon"
            )}
          >
            <CalendarIcon date={new Date(res.start_date)} />
          </div>
          <img
            className={classNames(
              ResponsiveVariables.screenClass,
              "ADP_Picture"
            )}
            src={
              res.image
                ? window._env_.REACT_APP_API_URL + res.image
                : BeerAndPizza
            }
            alt="Beer & Pizza"
            /* width="90%"
          height="200px" */
          ></img>
          {isAdmin ? (
            <div className="manage-buttons">
              <button
                className={classNames(
                  ResponsiveVariables.screenClass,
                  "edit-button"
                )}
                onClick={openEditActivityModal}
              >
                {editIcon}
              </button>
              <button
                className={classNames(
                  ResponsiveVariables.screenClass,
                  "delete-button"
                )}
                onClick={openDeleteActivityModal}
              >
                {deleteIcon}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        <ActivityDetailEditModal
          showModalActivityEdit={editActivityModalState}
          setShowModalActivityEdit={setEditActivityModalState}
          data={res}
          id={id}
          fetchData={fetchData}
        />
        <ActivityDetailDeleteModal
          showModalActivityDelete={deleteActivityModalState}
          setShowModalActivityDelete={setDeleteActivityModalState}
          deleteActivity={deleteActivity}
        />
        <div
          className={classNames(
            ResponsiveVariables.screenClass,
            "ADP_TitleResumeAndRegister"
          )}
        >
          <div
            className={classNames(
              ResponsiveVariables.screenClass,
              "ADP_TitleResume"
            )}
          >
            <h1>{res.title}</h1>
            <p>
              {formatDate(res.start_date)} {formatTime(res.start_date)}
              &nbsp;- {formatDate(res.end_date)} {formatTime(res.end_date)}
              <br></br>
              <div
                className={classNames(
                  ResponsiveVariables.screenClass,
                  "ADP_Location"
                )}
                onClick={() => {
                  window.open(
                    "https://maps.google.com/?q=" + res.location,
                    "_blank"
                  );
                }}
              >
                {res.location}
              </div>
              {res.organizer ? "Organized by " + res.organizer : null}
            </p>
          </div>

          <div
            className={classNames(
              ResponsiveVariables.screenClass,
              "ADP_buttons"
            )}
          >
            <div
              className={classNames(
                ResponsiveVariables.screenClass,
                "ADP_Register"
              )}
            >
              {buttonOutput()}
              <RegisterModal
                showModalRegister={showModalRegister}
                setShowModalRegister={setShowModalRegister}
                registerActivity={registerActivity}
                setRegisterStatus={setRegisterStatus}
                remark={remark}
                setRemark={setRemark}
                title={res.title}
                description={res.description}
                location={res.location}
                startdate={res.start_date}
                enddate={res.end_date}
                form={res.form}
                form_answers={form_answers}
                setFormAnswers={setFormAnswers}
              />
            </div>
            {isAdmin && new Date() < new Date(res.end_date) ? (
              <div>
                <button
                  onClick={openModalReminder}
                  className={classNames(
                    ResponsiveVariables.screenClass,
                    "ADP_Reminder"
                  )}
                  type="button"
                >
                  Send reminder
                </button>
                <ReminderModal
                  showModalReminder={showModalReminder}
                  setShowModalReminder={setShowModalReminder}
                  sendReminder={sendReminder}
                />
              </div>
            ) : (
              ""
            )}

            <div
              className={classNames(
                ResponsiveVariables.screenClass,
                "ADP_Ical"
              )}
            >
              <ICalEvent
                title={res.title}
                description={res.description}
                location={res.location}
                startdate={res.start_date}
                enddate={res.end_date}
              />
            </div>
          </div>
        </div>
        <div
          className={classNames(
            ResponsiveVariables.screenClass,
            "ADP_Description"
          )}
        >
          {res.description}
        </div>
        {/* <br></br>Published: {String(res.published)} */}
        <br></br>
        <div
          className={classNames(
            ResponsiveVariables.screenClass,
            "attendeelistcomponent"
          )}
        >
          <h2>Attendees</h2>
          <AttendeeList nr={id} />
        </div>
      </div>
    </div>
  );
}

export default ActivityDetailPage;
