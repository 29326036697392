import "./mollieTest.css";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

const MollieTest = () => {
  const [mollie, setMollie] = useState();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.mollie.com/v1/mollie.js";
    script.addEventListener("load", () => {
      setMollie(
        window.Mollie("pfl_Q3pRk87MnD", { locale: "nl_NL", testmode: false })
      );
    });
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (mollie) {
      //   var cardComponent = mollie.createComponent("card");
      //   cardComponent.mount("#card");

      var cardNumber = mollie.createComponent("cardNumber");
      cardNumber.mount("#card-number");

      var cardHolder = mollie.createComponent("cardHolder");
      cardHolder.mount("#card-holder");

      var expiryDate = mollie.createComponent("expiryDate");
      expiryDate.mount("#expiry-date");

      //   document.forms[0].addEventListener("submit", (e) => {
      //     e.preventDefault();
      //     setSubmitted(true);
      //     mollie.createToken().then(function (result) {
      //       dispatch({
      //         type: "set-cardToken",
      //         payload: { cardToken: result },
      //       });
      //       if (result.token) {
      //         sendPayment(result, contact, basket);
      //       }
      //       // Handle the result this can be either result.token or result.error.
      //     });
      //   });
    }
  }, [mollie]);

  return (
    <div class="mollie">
      <div class="wrapper">
        <form method="post" class="form" id="mcForm">
          <div class="form-fields">
            <div class="row">
              <div class="form-group form-group--card-holder">
                <label class="label" for="card-holder">
                  Name
                </label>
                <div id="card-holder"></div>
                <div
                  id="card-holder-error"
                  class="field-error"
                  role="alert"
                ></div>
              </div>
            </div>

            <div class="row">
              <div class="form-group form-group--card-number">
                <label class="label" for="card-number">
                  Card number
                </label>
                <div id="card-number"></div>
                <div
                  id="card-number-error"
                  class="field-error"
                  role="alert"
                ></div>
              </div>
            </div>

            <div class="row">
              <div class="form-group form-group--expiry-date">
                <label class="label" for="expiry-date">
                  Expiry date
                </label>
                <div id="expiry-date"></div>
                <div
                  id="expiry-date-error"
                  class="field-error"
                  role="alert"
                ></div>
              </div>
            </div>
          </div>

          <button id="submit-button" class="submit-button" type="submit">
            Pay €239.00
          </button>

          <div id="form-error" class="field-error" role="alert"></div>
        </form>
      </div>
    </div>
    // <form className="mollie">
    //   <div id="card"></div>
    // </form>
  );
};

export default MollieTest;
