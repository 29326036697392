import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import "./loginPage.css";
import ClientLogo from "../components/systemGeneralComponents/clientLogo.js";
import { useForm } from "react-hook-form";
import { useParams, Link, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

const eye = <FontAwesomeIcon icon={faEye} size="1x" />;

const LoginPage = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/login",
    title: "Login",
  });

  const { loginUser } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    console.log("useeffect!");
    console.log(location);
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: location.state ? location.state.email : "",
    }));
    setValue("email", location.state ? location.state.email : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    if (email.length > 0) {
      setLoginFailed(await loginUser(email, password));
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [formData, setFormData] = useState({
    email: "",
  });

  const [loginFailed, setLoginFailed] = useState(false);

  const handleEmailChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: event.target.value,
    }));
  };

  // Form validation
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  return (
    <section className={classNames(ResponsiveVariables.screenClass, "login")}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <div className={classNames(ResponsiveVariables.screenClass, "head")}>
          <ClientLogo width="100px" height="100px" display="flex" />
        </div>
        <input
          {...register("email", {
            required: "This field is required.",
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Please enter a valid email address.",
            },
            maxLength: {
              value: 150,
              message: "Use an email address with less than 150 characters.",
            },
          })}
          type="text"
          id="email"
          name="email"
          placeholder="E-Mail"
          className="text"
          onChange={handleEmailChange}
        />
        <p className="form-validation-error">
          {errors && errors.email && errors.email.message}
        </p>
        <div className="pass-wrapper">
          <input
            {...register("password", {
              required: "This field is required.",
            })}
            id="password"
            name="password"
            placeholder="Password"
            className={passwordShown ? "text" : "password"}
            type={passwordShown ? "text" : "password"}
          />
          <i onClick={togglePasswordVisiblity}>{eye}</i>{" "}
        </div>

        <p className="form-validation-error">
          {errors.password && errors.password.message}
        </p>

        {loginFailed && (
          <p className="form-validation-error">
            Wrong login or password combination
          </p>
        )}

        <div className="forgotpassword">
          <Link to="/forgotpassword" state={{ email: formData.email }}>
            Forgot password?
          </Link>
        </div>
        <button type="submit" className="btn-login">
          Login
        </button>
      </form>
    </section>
  );
};

export default LoginPage;
