import { useEffect, useState, useRef, useCallback } from "react";
import useAxios from "../utils/useAxios";
import { useParams } from "react-router-dom";
import "./ActivityDetailEditModal.css";
import Modal from "@mui/material/Modal";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  compareTwoDatesEqual,
  formatDate,
  formatTime,
} from "../utils/DateFunctions";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

const ActivityDetailEditModal = ({
  showModalActivityEdit,
  setShowModalActivityEdit,
  data,
  id,
  fetchData,
}) => {
  const [formData, setFormData] = useState({
    title: data.title,
    description: data.description,
    location: data.location,
    start_date: data.start_date,
    end_date: data.end_date,
    organizer: data.organizer,
    published: data.published,
  });
  const api = useAxios();
  const [activityImage, setActivityImage] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const inputRef = useRef(null);

  const handleClose = () => {
    console.log("closing!");
    setShowModalActivityEdit(false);
  };

  const onSubmit = (event) => {
    console.log("OnSubmit!");
    updateActivity();
    setShowModalActivityEdit(false);
  };

  const updateActivity = async () => {
    try {
      console.log(formData);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await api.put("/activity/" + id + "/", formData, config);
      console.log("TEST");
      fetchData();
    } catch {
      console.log("Something went wrong while updating activity");
    }
  };

  const handleTitleChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      title: event.target.value,
    }));
  };

  const handleDescriptionChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      description: event.target.value,
    }));
  };

  const handleLocationChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: event.target.value,
    }));
  };
  const handleStartDateChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      start_date: event.format("YYYY-MM-DD HH:mm:ss"),
    }));
  };
  const handleEndDateChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      end_date: event.format("YYYY-MM-DD HH:mm:ss"),
    }));
  };
  const handleOrganizerChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      organizer: event.target.value,
    }));
  };
  const handlePublishedChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      published: event.target.checked,
    }));
  };
  const handleImageChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: event.target.files[0],
    }));
    setActivityImage(URL.createObjectURL(event.target.files[0]));
  };

  useEffect(() => {
    console.log("filling in formData based on data!");
    console.log(data);
    setFormData((prevFormData) => ({
      ...prevFormData,
      title: data.title,
      description: data.description,
      location: data.location,
      start_date: data.start_date,
      end_date: data.end_date,
      organizer: data.organizer,
      published: data.published,
    }));
    if (data.image && activityImage === null) {
      setActivityImage(window._env_.REACT_APP_API_URL + data.image);
    }
  }, [data]);

  return (
    showModalActivityEdit && (
      <Modal
        open={showModalActivityEdit}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="edit-activity-modal"
      >
        <div
          className={classNames(
            ResponsiveVariables.screenClass,
            "edit-activity-modal-body"
          )}
        >
          <div className={classNames(ResponsiveVariables.screenClass, "title")}>
            <h1>Edit activity</h1>
          </div>
          <br></br>
          <form onSubmit={handleSubmit(onSubmit)} className="edit-profile-form">
            <div className="form-group">
              <label htmlFor="title">Title </label>
              <input
                {...register("title", { required: "This field is required" })}
                className="form-control"
                id="title"
                placeholder="Title"
                value={formData.title}
                onChange={handleTitleChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.title && errors.title.message}
            </p>

            <div className="form-group">
              <label htmlFor="description">Description </label>
              <textarea
                {...register("description", {
                  required: "This field is required",
                })}
                className="form-control"
                id="description"
                rows="10"
                placeholder="Description"
                value={formData.description}
                onChange={handleDescriptionChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.description && errors.description.message}
            </p>
            <div className="form-group">
              <label htmlFor="location">Location </label>
              <input
                {...register("location", {
                  required: "This field is required",
                })}
                className="form-control"
                id="location"
                placeholder="Location"
                value={formData.location}
                onChange={handleLocationChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.location && errors.location.message}
            </p>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Start date"
                  value={new Date(formData.start_date)}
                  inputFormat="DD/MM/YYYY HH:mm"
                  onChange={handleStartDateChange}
                  ampm={false}
                  // views={["day", "hours", "minutes"]}
                />
              </LocalizationProvider>
              <input
                {...register("start_date", {
                  required: "This field is required",
                })}
                id="start_date"
                hidden={true}
                value={formData.start_date}
              />
            </div>
            <p className="form-validation-error">
              {errors.start_date && errors.start_date.message}
            </p>
            <br></br>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="End date"
                  value={new Date(formData.end_date)}
                  inputFormat="DD/MM/YYYY HH:mm"
                  onChange={handleEndDateChange}
                  ampm={false}
                  // views={["day", "hours", "minutes"]}
                />
              </LocalizationProvider>
              <input
                {...register("end_date", {
                  required: "This field is required",
                })}
                id="end_date"
                hidden={true}
                value={formData.end_date}
              />
            </div>
            <p className="form-validation-error">
              {errors.end_date && errors.end_date.message}
            </p>
            <div className="form-group">
              <label htmlFor="organizer">Organizer </label>
              <input
                {...register("organizer", {
                  required: "This field is required",
                })}
                className="form-control"
                id="organizer"
                value={formData.organizer}
                onChange={handleOrganizerChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.organizer && errors.organizer.message}
            </p>
            <div className="form-group">
              <div className="pair">
                <label htmlFor="published">Gepubliceerd </label>
                <input
                  {...register("published", {})}
                  className="form-control"
                  id="published"
                  checked={formData.published}
                  onChange={handlePublishedChange}
                  type="checkbox"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="pair">
                <label htmlFor="foto">Foto </label>
                {activityImage ? (
                  <img src={activityImage} alt="" className="activity-image" />
                ) : (
                  <p>No logo avilable</p>
                )}
                <input
                  className="form-control"
                  id="image"
                  onChange={handleImageChange}
                  type="file"
                  style={{ display: "none" }}
                  ref={inputRef}
                />
                <button onClick={() => inputRef.current.click()} type="button">
                  Upload File
                </button>
              </div>
            </div>
            <div className="form-buttons">
              <button className="form-control btn btn-primary" type="submit">
                Save
              </button>
              <button
                className="form-control btn btn-secundary cancelbutton"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    )
  );
};

export default ActivityDetailEditModal;
