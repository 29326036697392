import "./Test.css";
import { useEffect, useState, useRef } from "react";
import useAxios from "../utils/useAxios.js";
import ClientLogo from "../components/systemGeneralComponents/clientLogo.js";
import { Link, useLocation, useParams } from "react-router-dom";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global.js";

const icon = L.icon({
  iconRetinaUrl: iconRetina,
  iconUrl: iconMarker,
  shadowUrl: iconShadow,
});

const Test = () => {
  const [membercount, setMembercount] = useState(0);
  const [vintageYear, setVintageYear] = useState(2015);
  const [avatar, setAvatar] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [yearEstablished, setYearEstablished] = useState(2000);
  const api = useAxios();

  useEffect(() => {
    const fetchTotalUserCount = async () => {
      try {
        const response = await api.get("/totalusercount/");
        setMembercount(response.data.total_user_count);
      } catch {
        console.log("Something went wrong");
      }
    };
    const fetchVintageYear = async () => {
      try {
        const response = await api.get("/vintageyear/");
        setVintageYear(response.data.vintage_year);
      } catch {
        console.log("Something went wrong");
      }
    };

    const fetchAvatar = async () => {
      try {
        const response = await api.get("/profile/avatar/");
        console.log("TEST");
        console.log(response.data);
        setAvatar(window._env_.REACT_APP_API_URL + response.data);
      } catch {
        console.log("Something went wrong while retreiving avatar");
      }
    };

    const fetchSettings = async () => {
      try {
        const response = await api.get("/settings/");
        console.log("Retrieving general settings");
        console.log(response.data);
        setOrganizationName(response.data.organization_name);
        setYearEstablished(response.data.established_year);
      } catch {
        console.log("Something went wrong while fetching settings");
      }
    };

    fetchTotalUserCount();
    fetchVintageYear();
    fetchAvatar();
    fetchSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var items = [
    {
      key: "Header",
      name: "Header Text",
      icon: "fa fa-header",
      static: true,
      content: "Placeholder Text...",
    },
    {
      key: "Paragraph",
      name: "Paragraph",
      static: true,
      icon: "fa fa-paragraph",
      content: "Placeholder Text...",
    },
  ];

  // <ReactFormBuilder
  //   url="path/to/GET/initial.json"
  //   toolbarItems={items}
  //   saveUrl="../data/POST/built/form.json"
  // />;
  const onPost = (data) => {
    const saveUrl = "test";
    console.log("onPost", saveUrl, data);
    // post(saveUrl, data);
  };

  const handleYearEstablishedChange = (event) => {
    setYearEstablished(event.target.value);
  };

  const handleOrganizationNameChange = (event) => {
    setOrganizationName(event.target.value);
  };

  return (
    <div className={classNames(ResponsiveVariables.screenClass, "test-body")}>
      <div className={classNames(ResponsiveVariables.screenClass, "info-box")}>
        <ClientLogo width="50px" height="50px" />

        {/* <img src={avatar} className="avatar" /> */}

        <p className="members">{membercount} members</p>
        <p className="vintage-year">Est. {vintageYear}</p>
      </div>
      <div
        className={classNames(ResponsiveVariables.screenClass, "test-detail")}
      >
        <button className="register-new-user-button">
          <Link
            className="new-user-link"
            to="/Register"
            style={{ color: "black" }}
            activeStyle={{ color: "red" }}
          >
            Register new user
          </Link>
        </button>
      </div>
      <div className="test-general">
        <div className="pair">
          <p>Organisation name</p>
          <input
            type="text"
            id="org_name"
            placeholder="Organisation name"
            className="text"
            value={organizationName}
            onChange={handleOrganizationNameChange}
          />
        </div>
        <div className="pair">
          <p>Year established</p>
          <input
            type="text"
            id="est_year"
            placeholder="Year established"
            className="text"
            value={yearEstablished}
            onChange={handleYearEstablishedChange}
          />
        </div>
      </div>
      <br></br>
      <div className="leafletmap">
        <MapContainer
          center={[51.505, -0.09]}
          zoom={13}
          scrollWheelZoom={false}
          style={{ height: "400px" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[51.505, -0.09]} icon={icon}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </div>

      {/*   <ReactFormGenerator
        form_action="/path/to/form/submit"
        form_method="POST"
        submitButton={
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        }
        backButton={
          <a href="/" className="btn btn-default btn-cancel btn-big">
            Back
          </a>
        }
      /> */}
    </div>
  );
};

export default Test;
