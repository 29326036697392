import React, { useRef, useEffect, useCallback } from "react";
import BeerAndPizza from "../img/temp/beerandpizza.jpeg";
import Modal from "@mui/material/Modal";
import "./ReminderModal.css";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

export const ReminderModal = ({
  showModalReminder,
  setShowModalReminder,
  sendReminder,
}) => {
  const handleClose = () => {
    console.log("closing!");
    setShowModalReminder(false);
  };
  const confirm = () => {
    console.log("Sending reminder!");
    sendReminder();
    setShowModalReminder(false);
  };

  return (
    <div>
      <Modal
        open={showModalReminder}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classNames(
          ResponsiveVariables.screenClass,
          "reminder_modal"
        )}
      >
        <div
          className={classNames(
            ResponsiveVariables.screenClass,
            "reminder_body"
          )}
        >
          <div className={classNames(ResponsiveVariables.screenClass, "title")}>
            Send reminder to all users which did not yet reply?
          </div>
          <div
            className={classNames(
              ResponsiveVariables.screenClass,
              "answer_buttons"
            )}
          >
            <button
              className={classNames(ResponsiveVariables.screenClass, "confirm")}
              onClick={confirm}
            >
              Send Reminder
            </button>
            <button
              className={classNames(ResponsiveVariables.screenClass, "cancel")}
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReminderModal;
