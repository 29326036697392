import { useEffect, useState, useContext } from "react";
// import useAxios from "../utils/useAxios";
import "./ForgotPasswordPage.css";
import ClientLogo from "../components/systemGeneralComponents/clientLogo.js";
import { useLocation } from "react-router-dom";
// import AuthContext from "../context/AuthContext";
import axios from "axios";
import { useForm } from "react-hook-form";
import ReactGA from "react-ga4";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global.js";

function ForgotPassword() {
  ReactGA.send({
    hitType: "pageview",
    page: "/forgotpassword",
    title: "Forgot Password",
  });

  const [succeeded, setSucceeded] = useState(false);
  const [completed, setCompleted] = useState(false);
  const baseURL = window._env_.REACT_APP_API_URL + "/api";
  const location = useLocation();
  console.log(location);

  useEffect(() => {
    console.log("useeffect!");
    console.log(location);
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: location.state.email,
    }));
    setValue("email", location.state.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [formData, setFormData] = useState({
    email: location.state.email,
  });

  const handleEmailChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: event.target.value,
    }));
    setValue("email", event.target.value);
    console.log(event.target.value);
  };

  const onSubmit = (data, event) => {
    console.log("test");
    requestPasswordReset(data.email);
  };

  const requestPasswordReset = async (email) => {
    try {
      const response = await axios.post(baseURL + "/requestpasswordreset/", {
        email: email,
      });
      setSucceeded(response.data.success);
    } catch {
      console.log("Something went wrong while requesting password reset link.");
    }
    setCompleted(true);
  };

  // Form validation
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  return (
    <section
      className={classNames(ResponsiveVariables.screenClass, "forgotpassword")}
      id="forgotpassword"
    >
      {completed && succeeded ? (
        <h1>
          We sent an email to reset your password. {"\n"} Please check your
          mailbox
        </h1>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} action="#">
          <div className={classNames(ResponsiveVariables.screenClass, "head")}>
            <ClientLogo width="100px" height="100px" display="flex" />
          </div>
          <input
            type="text"
            id="email"
            {...register("email", {
              required: "This field is required.",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Please enter a valid email address.",
              },
              maxLength: {
                value: 150,
                message: "Use an email address with less than 150 characters.",
              },
            })}
            name="email"
            placeholder="E-Mail"
            className="text"
            onChange={handleEmailChange}
          />
          <p className="form-validation-error">
            {errors.email && errors.email.message}
          </p>
          {completed && !succeeded && (
            <p>
              No profile found with this e-mail address. {"\n"} No e-mail was
              sent.
            </p>
          )}

          <button type="submit" className="btn-forgotpassword">
            Request new password
          </button>
        </form>
      )}
    </section>
  );
}

export default ForgotPassword;
