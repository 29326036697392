import { useContext, useEffect, useState } from "react";

import { Link, useLocation, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import "./Navbar.css";
import { navigationItems } from "../config";
import useAxios from "../utils/useAxios";
// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";
import { LetteredAvatar } from "../utils/LetteredAvatarFunction";

const Navbar = () => {
  const { user, logoutUser } = useContext(AuthContext);
  const location = useLocation();
  // const [navbarClicked, setNavbarClicked] = useState("");
  const [avatar, setAvatar] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const api = useAxios();

  // API CALLS ARE TO BE OPTIMIZED, NOT IDEAL SET_UP

  useEffect(() => {
    const fetchAvatar = async () => {
      console.log("called!");
      try {
        const response = await api.get("/profile/avatar/");
        console.log("TEST");
        console.log(response.data);
        if (response.data) {
          setAvatar(window._env_.REACT_APP_API_URL + response.data);
        } else {
          setAvatar("");
        }
      } catch {
        console.log("Something went wrong while retreiving avatar");
      }
    };
    fetchAvatar();
    const fetchID = async () => {
      try {
        const response = await api.get("/profile/id/");
        console.log(response.data);
        setId(response.data.id);
        setName(response.data.name);
        setSurname(response.data.surname);
        setIsAdmin(response.data.admin);
      } catch {
        console.log("Something went wrong while retreiving id");
      }
    };
    fetchID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <nav className={classNames(ResponsiveVariables.screenClass, "nav")}>
      {user ? (
        <div
          className={classNames(
            ResponsiveVariables.screenClass,
            "vertical-nav"
          )}
        >
          <Link to={"/user/" + id + "/"}>
            {avatar ? (
              <img src={avatar} alt="" className="avatar" />
            ) : (
              <LetteredAvatar
                name={name + " " + surname}
                size="60px"
                fontsize="24px"
              />
            )}
          </Link>
          <br></br>
          <b>{name}</b>
          <br></br>
          {user.id}
          <br></br>
          <hr />

          {navigationItems.sidebar
            .filter((item) =>
              !isAdmin ? item.access_level === "member" : true
            )
            .map((item) => (
              <Link
                key={item.name}
                to={item.to}
                className={
                  item.url_list.some((url) => location.pathname.includes(url))
                    ? "nav-sidebar-selected nav-item "
                    : "nav-sidebar-unselected nav-item"
                }
              >
                <img
                  key={item.name + ".img"}
                  src={
                    // location.pathname === item.to
                    item.url_list.some((url) => location.pathname.includes(url))
                      ? item.photoSelected
                      : item.photo
                  }
                  className="menu-icon"
                />
                {/* {item.name} */}
              </Link>
            ))}

          <button onClick={logoutUser} className="logout">
            Logout
          </button>
        </div>
      ) : null}
    </nav>
  );
};

export default Navbar;
