import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react";
import BeerAndPizza from "../img/temp/beerandpizza.jpeg";
import Modal from "@mui/material/Modal";
import "./AddUserModal.css";
import { useForm, Path } from "react-hook-form";
import useAxios from "../utils/useAxios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

const checkIcon = (
  <FontAwesomeIcon icon={faCircleCheck} className="circle-check-icon" />
);

const xmarkIcon = (
  <FontAwesomeIcon icon={faCircleXmark} className="circle-xmark-icon" />
);

export const AddUserModal = ({ showModalRegister, setShowModalRegister }) => {
  const api = useAxios();

  const [inviteSuccessful, setInviteSuccessful] = useState(false);
  const [inviteFailed, setInviteFailed] = useState(false);

  const [numberOfInvites, setNumberOfInvites] = useState(1);
  const [inputValues, setInputValues] = useState([""]);

  const [backendErrors, setBackendErrors] = useState();

  const handleClose = () => {
    console.log("closing!");
    setShowModalRegister(false);
    setInviteFailed(false);
    setInviteSuccessful(false);
  };

  const inviteUsers = async (data, event) => {
    console.log(inputValues);
    const result = await api.put("/invite/", inputValues);
    console.log(result.data);
    if (result) {
      setInviteSuccessful(result.data.registration_success);
      if (!result.data.registration_success) {
        console.log(result.data.errors);
        setBackendErrors(result.data.errors);
      }
    } else {
      setInviteFailed(true);
    }
  };

  const inputFieldChanged = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };

  const removeEmail = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues.splice(index, 1);
    setInputValues(newInputValues);
    const dictionary = Object.assign(
      {},
      ...newInputValues.map((value, index) => ({ [index]: value }))
    );
    reset(dictionary);
    setNumberOfInvites(numberOfInvites - 1);
  };

  const addEmail = () => {
    setNumberOfInvites(numberOfInvites + 1);
  };

  // Form validation
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm();

  return (
    <div>
      <Modal
        open={showModalRegister}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classNames(
          ResponsiveVariables.screenClass,
          "add_user_modal"
        )}
      >
        <div>
          {!inviteSuccessful && !inviteFailed && (
            <div
              className={classNames(
                ResponsiveVariables.screenClass,
                "add_user_body"
              )}
            >
              <div
                className={classNames(ResponsiveVariables.screenClass, "title")}
              >
                Invite user(s) to your organization?
              </div>
              <form onSubmit={handleSubmit(inviteUsers)}>
                {[...Array(numberOfInvites).keys()].map(
                  (elementInArray, index) => (
                    <div key={"div" + index}>
                      <div className="pair" key={"pair" + index}>
                        <input
                          key={"input" + index}
                          {...register(String(index), {
                            required: "This field is required.",
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Please enter a valid email address.",
                            },
                            maxLength: {
                              value: 150,
                              message:
                                "Use an email address with less than 150 characters.",
                            },
                          })}
                          type="text"
                          id={"email" + String(index)}
                          name={String(index)}
                          placeholder="E-mail"
                          className="text"
                          onChange={(e) =>
                            inputFieldChanged(index, e.target.value)
                          }
                        />
                        <button
                          key={"button" + index}
                          type="button"
                          className={
                            index === 0
                              ? "remove-email-button hide"
                              : "remove-email-button"
                          }
                          onClick={(e) => removeEmail(index, e.target.value)}
                        >
                          -
                        </button>
                      </div>
                      <p key={"p" + index} className="form-validation-error">
                        {errors &&
                          errors[String(index)] &&
                          errors[String(index)].message}
                        {backendErrors && backendErrors[String(index)]}
                      </p>
                    </div>
                  )
                )}
                <div className="pair">
                  <input
                    type="text"
                    id={"email"}
                    name={"email"}
                    placeholder="E-mail"
                    className="sample"
                    disabled={true}
                  />
                  <button
                    type="button"
                    className="add-email-button"
                    onClick={addEmail}
                  >
                    +
                  </button>
                </div>
                <button
                  className={classNames(
                    ResponsiveVariables.screenClass,
                    "submit"
                  )}
                  type="submit"
                >
                  Invite
                </button>
              </form>
              <p
                className={classNames(
                  ResponsiveVariables.screenClass,
                  "cancel"
                )}
                onClick={() => {
                  console.log("click");
                  handleClose();
                }}
              >
                Cancel
              </p>
            </div>
          )}
          {inviteSuccessful && (
            <div
              className={classNames(
                ResponsiveVariables.screenClass,
                "add_user_body"
              )}
              onClick={() => {
                console.log("click");
                handleClose();
              }}
            >
              <div>{checkIcon}</div>
              <p className="modal_accepted">Succesfully sent invite(s)!</p>
            </div>
          )}
          {inviteFailed && (
            <div
              className={classNames(
                ResponsiveVariables.screenClass,
                "add_user_body"
              )}
              onClick={() => {
                console.log("click");
                handleClose();
              }}
            >
              <div>{xmarkIcon}</div>
              <p className="modal_declined">You have declined the activity!</p>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AddUserModal;
