import React from "react";

// const name = "";

export function LetteredAvatar({ name, size = "100px", fontsize = "40px" }) {
  function getInitials(name) {
    const splitName = name.split(" ").filter((i) => i);
    return (
      `${splitName.length > 0 ? splitName[0][0] : "?"}` +
      `${splitName.length > 1 ? splitName[1][0] : ""}` +
      `${splitName.length > 2 ? splitName[2][0] : ""}`
    );
  }
  function generateBackground(name) {
    let hash = 0;
    let i;

    for (i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    // name.charCodeAt() return an int between 0 and 65535
    // left shift (<<)  operator moves to left by number of specified
    // bites after <<. The whole for loop will create a color hash
    // based on username length
    let color = "hsl(";
    console.log(hash);
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      console.log(value);
      if (i === 0) {
        color += (value / 255) * 360;
        color += ",";
      } else if (i === 1) {
        color += 25 + (value / 255) * 70;
        color += "%,";
      } else if (i === 2) {
        color += 85 + (value / 255) * 10;
        color += "%)";
      }
      // color += `00${value.toString(16)}`.slice(-2);
    }
    console.log(color);
    // return "hsl(" + 360 * Math.random() + ',' +
    //              (25 + 70 * Math.random()) + '%,' +
    //              (85 + 10 * Math.random()) + '%)'
    // }

    return color;
  }
  let initials = getInitials(name);
  let color = generateBackground(name);
  const customStyle = {
    display: "flex",
    borderRadius: "100px",
    border: "2px solid black",
    verticalAlign: "middle",
    height: size,
    width: size,
    color: "black",
    margin: "20px auto",
    background: color,
    fontWeight: "bold",
    fontSize: fontsize,
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  };
  return (
    <div style={customStyle}>
      <span style={{ margin: "auto" }}> {initials} </span>
    </div>
  );
}
