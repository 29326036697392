import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react";
import ICSCalenderIcon from "../img/calendar-icons/ics-calendar-icon.png";
import GoogleCalenderIcon from "../img/calendar-icons/google-calendar-icon.png";
import OutlookCalenderIcon from "../img/calendar-icons/outlook-calendar-icon.png";
import Office365CalenderIcon from "../img/calendar-icons/office365-calendar-icon.png";
import YahooCalenderIcon from "../img/calendar-icons/yahoo-calendar-icon.png";

import Modal from "@mui/material/Modal";
import "./RegisterModal.css";
import { google, outlook, office365, yahoo, ics } from "calendar-link";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

const checkIcon = (
  <FontAwesomeIcon icon={faCircleCheck} className="circle-check-icon" />
);

const xmarkIcon = (
  <FontAwesomeIcon icon={faCircleXmark} className="circle-xmark-icon" />
);

export const RegisterModal = ({
  showModalRegister,
  setShowModalRegister,
  registerActivity,
  setRegisterStatus,
  remark,
  setRemark,
  title,
  description,
  location,
  startdate,
  enddate,
  form = { form: [] },
  form_answers,
  setFormAnswers,
}) => {
  const [acceptSaved, setAcceptSaved] = useState(false);
  const [declineSaved, setDeclineSaved] = useState(false);

  const handleClose = () => {
    console.log("closing!");
    if (acceptSaved || declineSaved) {
      window.location.reload();
    }
    setAcceptSaved(false);
    setDeclineSaved(false);
    setShowModalRegister(false);
  };
  const acceptActivity = () => {
    console.log("Accepting activity!");
    setRegisterStatus("A");
    registerActivity("A");
    setAcceptSaved(true);
  };

  const declineActivity = () => {
    console.log("Declining activity!");
    setRegisterStatus("D");
    registerActivity("D");
    setDeclineSaved(true);
  };

  const handleDescriptionChange = (event) => {
    setRemark(event.target.value);
  };

  const handleAnswerChange = (questionIndex, answerIndex, value) => {
    let newFormAnswers = form_answers;
    let json = newFormAnswers;
    json.form[questionIndex].answer = value;
    setFormAnswers(json);
  };

  const event = {
    title: title,
    description: description,
    start: startdate,
    end: enddate,
    location: location,
  };

  //Improvement to use deeplink instead of outlook.live.com link
  let outlookLink = outlook(event);
  outlookLink = outlookLink.substring(outlookLink.indexOf("?"));
  outlookLink =
    "https://outlook.office.com/calendar/0/deeplink/compose" + outlookLink;
  return (
    <div>
      <Modal
        open={showModalRegister}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classNames(
          ResponsiveVariables.screenClass,
          "register_modal"
        )}
      >
        <div>
          {!acceptSaved && !declineSaved && (
            <div
              className={classNames(
                ResponsiveVariables.screenClass,
                "register_body"
              )}
            >
              <div
                className={classNames(ResponsiveVariables.screenClass, "title")}
              >
                Register to this activity?
              </div>
              <textarea
                className={classNames(
                  ResponsiveVariables.screenClass,
                  "remark"
                )}
                id="description"
                rows="10"
                placeholder="Optional: leave a remark"
                value={remark}
                onChange={handleDescriptionChange}
              />
              <div className="questions">
                {form.form &&
                  form.form.map((formComponent, questionIndex) => {
                    return (
                      <div key={"question-div" + questionIndex}>
                        <hr key={"question-hr" + questionIndex}></hr>
                        <div
                          className="pair"
                          key={"question-pair" + questionIndex}
                        >
                          <p
                            className="custom-form-question-id"
                            key={"question-id" + questionIndex}
                          >
                            {questionIndex + 1}.
                          </p>
                          <p className="custom-form-question">
                            {formComponent.question}
                          </p>
                        </div>
                        <div
                          className="custom-form-answers"
                          key={"answers" + questionIndex}
                        >
                          {formComponent.answers.map((answer, answerIndex) => (
                            <div
                              className="pair"
                              key={"answer-pair" + questionIndex + answerIndex}
                            >
                              <input
                                key={"answer" + questionIndex + answerIndex}
                                type="radio"
                                className="form-control custom-form-answer-input"
                                value={answer}
                                name={questionIndex}
                                onChange={(e) =>
                                  handleAnswerChange(
                                    questionIndex,
                                    answerIndex,
                                    e.target.value
                                  )
                                }
                              />
                              <p className="custom-form-answer-value">
                                {answer}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div
                className={classNames(
                  ResponsiveVariables.screenClass,
                  "answer_buttons"
                )}
              >
                <button
                  className={classNames(
                    ResponsiveVariables.screenClass,
                    "accept"
                  )}
                  onClick={acceptActivity}
                >
                  Accept
                </button>
                <button
                  className={classNames(
                    ResponsiveVariables.screenClass,
                    "decline"
                  )}
                  onClick={declineActivity}
                >
                  Decline
                </button>
              </div>
              <p
                className={classNames(
                  ResponsiveVariables.screenClass,
                  "cancel"
                )}
                onClick={() => {
                  console.log("click");
                  handleClose();
                }}
              >
                Cancel
              </p>
            </div>
          )}
          {acceptSaved && (
            <div
              className={classNames(
                ResponsiveVariables.screenClass,
                "register_body"
              )}
              onClick={() => {
                console.log("click");
                handleClose();
              }}
            >
              <div>{checkIcon}</div>
              <p className="modal_accepted">You have succesfully accepted!</p>
              <p>Add this event to your calendar:</p>
              <div className="event_button_list">
                <button
                  className="event_button"
                  onClick={() => {
                    window.open(ics(event), "_blank", "noopener,noreferrer");
                  }}
                >
                  <img src={ICSCalenderIcon} width={"25px"} height={"25px"} />
                </button>
                <button
                  className="event_button"
                  onClick={() => {
                    window.open(google(event), "_blank", "noopener,noreferrer");
                  }}
                >
                  <img
                    src={GoogleCalenderIcon}
                    width={"25px"}
                    height={"25px"}
                  />
                </button>
                <button
                  className="event_button"
                  onClick={() => {
                    window.open(outlookLink, "_blank", "noopener,noreferrer");
                  }}
                >
                  <img
                    src={OutlookCalenderIcon}
                    width={"25px"}
                    height={"25px"}
                  />
                </button>
                <button
                  className="event_button"
                  onClick={() => {
                    window.open(
                      office365(event),
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  <img
                    src={Office365CalenderIcon}
                    width={"25px"}
                    height={"25px"}
                  />
                </button>
                <button
                  className="event_button"
                  onClick={() => {
                    window.open(yahoo(event), "_blank", "noopener,noreferrer");
                  }}
                >
                  <img src={YahooCalenderIcon} width={"25px"} height={"25px"} />
                </button>
              </div>
            </div>
          )}
          {declineSaved && (
            <div
              className={classNames(
                ResponsiveVariables.screenClass,
                "register_body"
              )}
              onClick={() => {
                console.log("click");
                handleClose();
              }}
            >
              <div>{xmarkIcon}</div>
              <p className="modal_declined">You have declined the activity!</p>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default RegisterModal;
