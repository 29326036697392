import { useEffect, useState, useContext } from "react";
// import useAxios from "../utils/useAxios";
import "./VerifyPage.css";
import ClientLogo from "../components/systemGeneralComponents/clientLogo.js";
import { useParams, Link } from "react-router-dom";
// import AuthContext from "../context/AuthContext";
import axios from "axios";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

function Verify() {
  const { uid, token } = useParams();
  const [verified, setVerified] = useState(false);
  const baseURL = window._env_.REACT_APP_API_URL + "/api";

  useEffect(() => {
    console.log("useeffect!");
    console.log(uid);
    console.log(token);
    verify();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, token]);

  const verify = async () => {
    try {
      const response = await axios.get(
        baseURL + "/verify/" + uid + "/" + token
      );
      setVerified(response.data.verified);
    } catch {
      console.log("Something went wrong while verifying user");
    }
  };

  return (
    <section
      className={classNames(ResponsiveVariables.screenClass, "verify")}
      id="verify"
    >
      <form>
        <div className={classNames(ResponsiveVariables.screenClass, "head")}>
          <ClientLogo width="100px" height="100px" display="flex" />
          <h1 className="verified_text">
            {verified
              ? " \n Verified!"
              : " \n Link is invalid or expired, could not validate account. \n \n Try registering again."}
          </h1>
        </div>
        {verified ? (
          <Link to="/" className="btn-verify">
            Login
          </Link>
        ) : (
          <Link to="/register" className="btn-verify">
            Register
          </Link>
        )}
      </form>
    </section>
  );
}

export default Verify;
