const compareTwoDatesEqual = (start_date, end_date) => {
  // 17-12-2019
  const firstDate = `${start_date.getDay()}-${start_date.getMonth()}-${start_date.getFullYear()}`;

  const secondDate = `${end_date.getDay()}-${end_date.getMonth()}-${end_date.getFullYear()}`;

  return firstDate === secondDate;
};

const PastStartDate = (start_date) => {
  // 17-12-2019
  return start_date < new Date();
};
/* const options = {
    year: "numeric",
    month: "long",
    day: "numeric", 
    numeric, long, short, 2-digit, ...
    */

const formatDate = (dateString, yearFormat, monthFormat, dayFormat) => {
  const options = {
    year: yearFormat,
    month: monthFormat,
    day: dayFormat,
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};
export default formatDate;

const formatTime = (timeString) => {
  const options = {
    hour: "numeric",
    minute: "numeric",
  };
  return new Date(timeString).toLocaleTimeString(undefined, options);
};

const yearFromDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dateString).getFullYear();
};

const monthFromDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dateString).getMonth() + 1;
};

const dayFromDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dateString).getDate();
};

const hoursFromDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dateString).getHours();
};

const minutesFromDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dateString).getMinutes();
};

export {
  compareTwoDatesEqual,
  PastStartDate,
  formatDate,
  formatTime,
  yearFromDate,
  monthFromDate,
  dayFromDate,
  hoursFromDate,
  minutesFromDate,
};
