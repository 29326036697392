import "./Footer.css";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

const Footer = () => {
  return (
    <div
      className={classNames(ResponsiveVariables.screenClass, "parent-footer")}
    >
      <div
        className={classNames(ResponsiveVariables.screenClass, "footer-div")}
      >
        Powered by QUORUM - v0.1.27 - Beta
      </div>
    </div>
  );
};

export default Footer;
