import React, { useEffect, useState } from "react";
import useAxios from "../utils/useAxios";
import { useNavigate } from "react-router-dom";
import "./AttendeeList.css";
import ClientLogo from "../components/systemGeneralComponents/clientLogo.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faUserPen } from "@fortawesome/free-solid-svg-icons";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

const userIcon = (
  <FontAwesomeIcon icon={faUser} size="1x" style={{ color: "#2f96ff" }} />
);
const adminUserIcon = (
  <FontAwesomeIcon icon={faUserPen} size="1x" style={{ color: "#2f96ff" }} />
);

const AttendeeList = ({ nr }) => {
  const navigate = useNavigate();
  const [res, setRes] = useState("");
  const api = useAxios();
  const [attendeeCount, setAttendeeCount] = useState({ A: 0, D: 0, R: 0 });
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("activity/attendeelist/" + nr);
        console.log("TEST");
        console.log(response.data);
        setRes(response.data.response);
        setAttendeeCount(response.data.attendee_count);
      } catch {
        console.log("Something went wrong while retreiving users");
      }
    };
    fetchData();
    fetchID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchID = async () => {
    try {
      const response = await api.get("/profile/id/");
      setIsAdmin(response.data.admin);
    } catch {
      console.log("Something went wrong while retreiving id");
    }
  };

  return (
    <div
      className={classNames(ResponsiveVariables.screenClass, "attendee-table")}
    >
      <div
        className={classNames(
          ResponsiveVariables.screenClass,
          "attendee-count"
        )}
      >
        Accepted: {attendeeCount["A"]} Declined: {attendeeCount["D"]} Unknown:{" "}
        {attendeeCount["R"]}
      </div>
      {Array.isArray(res)
        ? res
            .sort((user1, user2) =>
              user1.status < user2.status
                ? -1
                : user1.status > user2.status
                ? 1
                : user1.name + user1.surname > user2.name + user1.surname
                ? 1
                : -1
            )
            .map((user) => (
              <div
                className={classNames(
                  ResponsiveVariables.screenClass,
                  "attendee-table-row"
                )}
                data-label="attendee"
                key={(user = user)}
              >
                <div
                  className={classNames(
                    ResponsiveVariables.screenClass,
                    "attendee-table-row-top"
                  )}
                >
                  <div
                    className={classNames(
                      ResponsiveVariables.screenClass,
                      "attendee"
                    )}
                  >
                    {user.name + " " + user.surname + " "}
                  </div>
                  <div
                    className={classNames(
                      ResponsiveVariables.screenClass,
                      "attendee-table-row-register-status",
                      user.status === "A"
                        ? "accepted"
                        : user.status === "D"
                        ? "declined"
                        : "notregisteredyet"
                    )}
                    data-label="attendee"
                  >
                    {user.status === "A"
                      ? "Accepted"
                      : user.status === "D"
                      ? "Declined"
                      : "Unknown"}
                  </div>
                </div>

                {isAdmin ? (
                  <div
                    className={classNames(
                      ResponsiveVariables.screenClass,
                      "attendee-table-row-bottom"
                    )}
                  >
                    {user.remark && (
                      <>
                        <p>
                          <span className="blue-bold">Remark: </span>{" "}
                          {user.remark}
                        </p>
                      </>
                    )}
                    {user.form_answers.form && user.form_answers.form != {} && (
                      <div>
                        <p className="blue-bold">Answers: </p>
                        <div>
                          {user.form_answers.form &&
                            user.form_answers.form.map(
                              (formComponent, questionIndex) => {
                                return (
                                  <p>
                                    {formComponent.question}{" "}
                                    <span className="bold">
                                      {formComponent.answer}
                                    </span>
                                  </p>
                                );
                              }
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))
        : null}
    </div>
  );
};

export default AttendeeList;
