import { useEffect, useState } from "react";
import useAxios from "../utils/useAxios";
import "./Dashboard.css";
import ClientLogo from "../components/systemGeneralComponents/clientLogo.js";
import { useNavigate } from "react-router-dom";
import CalendarIcon from "react-calendar-icon";
import {
  compareTwoDatesEqual,
  PastStartDate,
  formatDate,
  formatTime,
} from "../utils/DateFunctions";
import React from "react";
import AddActivityModal from "./AddActivityModal.js";
import ReactGA from "react-ga4";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faUserGear } from "@fortawesome/free-solid-svg-icons";

const addresslIcon = (
  <FontAwesomeIcon icon={faLocationDot} style={{ color: "#2f96ff" }} />
);

const clockIcon = (
  <FontAwesomeIcon icon={faClock} style={{ color: "#2f96ff" }} />
);
const userIcon = <FontAwesomeIcon icon={faUser} style={{ color: "#2f96ff" }} />;
const adminIcon = (
  <FontAwesomeIcon icon={faUserGear} style={{ color: "#2f96ff" }} />
);

const Dashboard = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/dashboard",
    title: "Dashboard",
  });

  const [membercount, setMembercount] = useState(0);
  const [vintageYear, setVintageYear] = useState(1998);
  const [activities, setActivities] = useState({});
  const [addActivityModalState, setAddActivityModalState] = useState(false);
  const [users, setUsers] = useState({});
  const [avatar, setAvatar] = useState("");
  const [isAdmin, setIsAdmin] = useState(true);
  const [refs, setRefs] = useState({});
  const api = useAxios();
  const navigate = useNavigate();

  // the value of the search field
  const [name, setName] = useState("");

  // the search result
  const [foundUsers, setFoundUsers] = useState(users);
  const filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = users.filter((user) => {
        return (
          user.name.toLowerCase().startsWith(keyword.toLowerCase()) ||
          user.surname.toLowerCase().startsWith(keyword.toLowerCase())
        );
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFoundUsers(results);
    } else {
      setFoundUsers(users);
      // If the text field is empty, show all users
    }

    setName(keyword);
  };

  const memberClick = (id) => {
    navigate("/user/" + id);
  };

  const activityClick = (id) => {
    console.log(id);
    navigate("/activityDetail/" + id);
  };

  // get logged in user
  const fetchID = async () => {
    try {
      const response = await api.get("/profile/id/");
      setIsAdmin(response.data.admin);
    } catch {
      console.log("Something went wrong while retreiving id");
    }
  };

  const openAddActivityModal = () => {
    setAddActivityModalState((addActivityModalState) => !addActivityModalState);
    console.log("switched state!");
  };

  const scrollToActivity = () => {
    try {
      const activity_id = activities
        .filter(
          (activity) =>
            Date.parse(activity.start_date) >= Date.parse(new Date())
        )
        .reduce((agg, activity) =>
          agg.start_date <= activity.start_date ? agg : activity
        ).id;
      refs[activity_id].current.parentNode.scrollTop =
        refs[activity_id].current.offsetTop -
        refs[activity_id].current.parentNode.offsetTop;
    } catch {
      console.log("Could not scroll to ref");
    }
  };

  const firstActivity = (activity_id) => {
    const first_activity_id = activities
      .filter(
        (activity) => Date.parse(activity.start_date) >= Date.parse(new Date())
      )
      .reduce((agg, activity) =>
        agg.start_date <= activity.start_date ? agg : activity
      ).id;
    return activity_id === first_activity_id;
  };

  useEffect(() => {
    const fetchTotalUserCount = async () => {
      try {
        const response = await api.get("/totalusercount/");
        setMembercount(response.data.total_user_count);
      } catch {
        console.log("Something went wrong");
      }
    };
    const fetchVintageYear = async () => {
      try {
        const response = await api.get("/vintageyear/");
        setVintageYear(response.data.vintage_year);
      } catch {
        console.log("Something went wrong");
      }
    };
    const fetchActivities = async () => {
      try {
        const response = await api.get("/dashboard/activities");

        const result = response.data.reduce((acc, value) => {
          acc[value.id] = React.createRef();
          return acc;
        }, {});
        setRefs(result);

        setActivities(response.data);
        console.log(response);
      } catch {
        console.log("Something went wrong");
      }
    };
    const fetchUsers = async () => {
      try {
        const response = await api.get("/dashboard/users");
        setUsers(response.data);
        setFoundUsers(response.data);
        console.log(response);
      } catch {
        console.log("Something went wrong");
      }
    };

    const fetchAvatar = async () => {
      try {
        const response = await api.get("/profile/avatar/");
        console.log("TEST");
        console.log(response.data);
        setAvatar(window._env_.REACT_APP_API_URL + response.data);
      } catch {
        console.log("Something went wrong while retreiving avatar");
      }
    };

    fetchTotalUserCount();
    fetchVintageYear();
    fetchActivities();
    fetchUsers();
    fetchAvatar();
    fetchID();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollToActivity();
  }, [refs]);

  return (
    <div className={classNames(ResponsiveVariables.screenClass, "dashboard")}>
      <div
        className={classNames(
          ResponsiveVariables.screenClass,
          "dashboard-components"
        )}
      >
        <div
          className={classNames(ResponsiveVariables.screenClass, "info-box")}
        >
          <div className="title_items">
            <ClientLogo width="40px" height="40px" />

            <p className="members">{membercount} members</p>
            <p className="vintage-year">Est. {vintageYear}</p>
          </div>
          <div className="slogan">
            " Good Friends, Good Times, Good Memories "
          </div>
        </div>

        <hr></hr>
        <div className={classNames(ResponsiveVariables.screenClass, "box1")}>
          <div
            className={classNames(
              ResponsiveVariables.screenClass,
              "activities-box"
            )}
          >
            <h6>Activities</h6>

            <div
              className={classNames(
                ResponsiveVariables.screenClass,
                "activity-list"
              )}
            >
              {activities && activities.length > 0 ? (
                activities
                  .sort((activity1, activity2) =>
                    activity1.start_date > activity2.start_date ? 1 : -1
                  )
                  .map((activity) => (
                    <li
                      key={activity.id}
                      ref={refs[activity.id]}
                      className={classNames(
                        ResponsiveVariables.screenClass,
                        "activity",
                        {
                          "grayed-out": PastStartDate(
                            new Date(activity.start_date)
                          ),
                        },
                        activity.registration_status === "A"
                          ? "green-border"
                          : activity.registration_status === "D"
                          ? "red-border"
                          : "blue-border",
                        { first_activity: firstActivity(activity.id) }
                      )}
                      onClick={() => activityClick(activity.id)}
                    >
                      <div
                        className={classNames(
                          ResponsiveVariables.screenClass,
                          "activity-left-column"
                        )}
                      >
                        <div
                          className={classNames(
                            ResponsiveVariables.screenClass,
                            "parent-calendaricon"
                          )}
                        >
                          <div className="calendaricon">
                            <CalendarIcon
                              date={new Date(activity.start_date)}
                            />
                          </div>
                        </div>
                        <div
                          className={classNames(
                            ResponsiveVariables.screenClass,
                            "dashboard-activity-register-status-left",
                            activity.registration_status === "A"
                              ? "accepted"
                              : activity.registration_status === "D"
                              ? "declined"
                              : "notregisteredyet"
                          )}
                          data-label="attendee"
                        >
                          {activity.registration_status === "A"
                            ? "Accepted"
                            : activity.registration_status === "D"
                            ? "Declined"
                            : "Unknown"}
                        </div>
                      </div>
                      <div
                        className={classNames(
                          ResponsiveVariables.screenClass,
                          "activity-list-item"
                        )}
                      >
                        <div
                          className={classNames(
                            ResponsiveVariables.screenClass,
                            "activity-title"
                          )}
                        >
                          {activity.title}
                        </div>

                        <div
                          className={classNames(
                            ResponsiveVariables.screenClass,
                            "activity-item-detail"
                          )}
                        >
                          {compareTwoDatesEqual(
                            new Date(activity.start_date),
                            new Date(activity.end_date)
                          ) && (
                            <div
                              className={classNames(
                                ResponsiveVariables.screenClass,
                                "dashboard-when"
                              )}
                            >
                              <div
                                className={classNames(
                                  ResponsiveVariables.screenClass,
                                  "dashboard-clockicon"
                                )}
                              >
                                {clockIcon}{" "}
                              </div>
                              <div
                                className={classNames(
                                  ResponsiveVariables.screenClass,
                                  "dashboard-date"
                                )}
                              >
                                {formatDate(
                                  activity.start_date,
                                  "numeric",
                                  "short",
                                  "numeric"
                                )}{" "}
                                {<br></br>}
                                {formatTime(activity.start_date)}
                                &nbsp;- {formatTime(activity.end_date)}
                              </div>
                            </div>
                          )}
                          {!compareTwoDatesEqual(
                            new Date(activity.start_date),
                            new Date(activity.end_date)
                          ) && (
                            <div
                              className={classNames(
                                ResponsiveVariables.screenClass,
                                "dashboard-when"
                              )}
                            >
                              <div
                                className={classNames(
                                  ResponsiveVariables.screenClass,
                                  "dashboard-clockicon"
                                )}
                              >
                                {clockIcon}{" "}
                              </div>
                              <div
                                className={classNames(
                                  ResponsiveVariables.screenClass,
                                  "dashboard-date"
                                )}
                              >
                                {formatDate(
                                  activity.start_date,
                                  "numeric",
                                  "short",
                                  "numeric"
                                )}{" "}
                                {formatTime(activity.start_date)}
                                &nbsp;
                                {<br></br>}
                                {formatDate(
                                  activity.end_date,
                                  "numeric",
                                  "short",
                                  "numeric"
                                )}{" "}
                                {formatTime(activity.end_date)}
                              </div>
                            </div>
                          )}

                          <div
                            className={classNames(
                              ResponsiveVariables.screenClass,
                              "dashboard-where"
                            )}
                          >
                            <div className="where-icon-location">
                              <div
                                className={classNames(
                                  ResponsiveVariables.screenClass,
                                  "dashboard-addresicon"
                                )}
                              >
                                {addresslIcon}
                              </div>
                              {/* <div>{activity.organizer}</div> */}
                              <div
                                className={classNames(
                                  ResponsiveVariables.screenClass,
                                  "dashboard-location"
                                )}
                              >
                                {activity.location}
                              </div>
                            </div>
                          </div>
                          {/* {activity.registration_status} */}
                        </div>
                      </div>
                    </li>
                  ))
              ) : (
                <>
                  <p>No results found!</p>
                  {isAdmin ? (
                    <button
                      onClick={openAddActivityModal}
                      className="dashboard-addactivitybutton"
                      type="button"
                    >
                      {ResponsiveVariables.isMobile ? "+" : "+ Add activity"}
                    </button>
                  ) : (
                    ""
                  )}

                  <AddActivityModal
                    showModal={addActivityModalState}
                    setShowModal={setAddActivityModalState}
                    // data={res}
                    // id={id}
                    // fetchData={fetchData}
                  />
                </>
              )}
            </div>
          </div>

          <div
            className={classNames(ResponsiveVariables.screenClass, "users-box")}
          >
            <h6>Members</h6>
            <input
              type="search"
              onChange={filter}
              value={name}
              id="membersearch"
              placeholder="Search..."
              className="membersearch"
            />
            <div
              className={classNames(
                ResponsiveVariables.screenClass,
                "dashboard-user-list"
              )}
            >
              {foundUsers && foundUsers.length > 0 ? (
                foundUsers
                  .sort((user1, user2) =>
                    user1.name + user1.surname > user2.name + user2.surname
                      ? 1
                      : -1
                  )
                  .map((user) => (
                    <li
                      key={user.user}
                      className="user"
                      // onClick={() => memberClick(user.user)}
                    >
                      {/* <img src={avatar} className="tomavatar" /> */}
                      <div
                        className={classNames(
                          ResponsiveVariables.screenClass,
                          "dashboard-user-list-item"
                        )}
                      >
                        <div
                          className={classNames(
                            ResponsiveVariables.screenClass,
                            "dashboard-user-icon"
                          )}
                        >
                          {user.admin ? adminIcon : userIcon}
                        </div>
                        <div
                          className={classNames(
                            ResponsiveVariables.screenClass,
                            "dashboard-user-name"
                          )}
                        >
                          {user.name + " " + user.surname}
                        </div>
                        {/* <div
                        className={classNames(
                          ResponsiveVariables.screenClass,
                          "dashboard-user-admin"
                        )}
                      >
                        {user.admin ? "(Admin)" : ""}
                      </div> */}
                      </div>
                    </li>
                  ))
              ) : (
                <p>No results found!</p>
              )}
            </div>
          </div>
        </div>
        {/* <div className="files-box">
        <ClientLogo width="50px" height="50px" /> files
      </div> */}
      </div>
      <div className={classNames(ResponsiveVariables.screenClass, "idea-box")}>
        <p>
          {ResponsiveVariables.isMobile
            ? "Idea to improve Quorum?"
            : "Do you have an idea to improve Quorum?"}
        </p>
        <button
          onClick={() =>
            (window.location =
              "mailto:info@quorum.be?subject=I have an idea to improve Quorum!&body=I would like to propose the following to improve Quorum...")
          }
        >
          {ResponsiveVariables.isMobile ? "Contact us!" : "Contact us here!"}
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
