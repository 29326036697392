import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import { GlobalDndContext } from "./dnd-context";
import TraceProvider from "./tracing";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GlobalDndContext>
    {/* <React.StrictMode> */}
    <TraceProvider>
      <Router>
        <AuthProvider>
          <App />
          <div className="landscape">
            <div className="phone"></div>
            <div className="message">Please rotate your device!</div>
          </div>
        </AuthProvider>
      </Router>
    </TraceProvider>
    {/* </React.StrictMode> */}
  </GlobalDndContext>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
