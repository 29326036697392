import React, { useEffect, useState } from "react";
import useAxios from "../utils/useAxios";
import { useNavigate } from "react-router-dom";
import "./activitiesPage.css";
// import BeerAndPizza from "../img/temp/beerandpizza.jpeg";
import BeerAndPizza from "../img/systemGeneral/upcomingevent.png";
import AddActivityModal from "./AddActivityModal";
import {
  compareTwoDatesEqual,
  PastStartDate,
  formatDate,
  formatTime,
} from "../utils/DateFunctions";
import ReactGA from "react-ga4";
import CalendarIcon from "react-calendar-icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

const forwardIconBlue = (
  <FontAwesomeIcon
    icon={faCircleChevronRight}
    size="3x"
    style={{ color: "#2f96ff" }}
  />
);
const backwardIconBlue = (
  <FontAwesomeIcon
    icon={faCircleChevronLeft}
    size="3x"
    style={{ color: "#2f96ff" }}
  />
);
const forwardIconGray = (
  <FontAwesomeIcon
    icon={faCircleChevronRight}
    size="3x"
    style={{ color: "#808080" }}
  />
);
const backwardIconGray = (
  <FontAwesomeIcon
    icon={faCircleChevronLeft}
    size="3x"
    style={{ color: "#808080" }}
  />
);
const clockIcon = (
  <FontAwesomeIcon icon={faClock} style={{ color: "#2f96ff" }} />
);
const addresslIcon = (
  <FontAwesomeIcon icon={faLocationDot} style={{ color: "#2f96ff" }} />
);

function ActivitiesPage() {
  ReactGA.send({
    hitType: "pageview",
    page: "/activities",
    title: "Activities",
  });

  const [activity1Details, setActivity1Details] = useState("");
  const [activity2Details, setActivity2Details] = useState("");
  const [activity3Details, setActivity3Details] = useState("");
  const [activity1Visible, setActivity1Visible] = useState(true);
  const [activity2Visible, setActivity2Visible] = useState(true);
  const [activity3Visible, setActivity3Visible] = useState(true);
  const [noActivities, setNoActivities] = useState(false);
  const [activityNumbers, setActivityNumbers] = useState([]);
  const [registerStatus, setRegisterStatus] = useState(["R", "R", "R"]); //R(egistered) or A(ccepted) or D(eclined)
  const [page, setPage] = useState(0);
  const [previousPage, setPreviousPage] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);

  // const [registerStatus, setRegisterStatus] = useState(""); //R(egistered) or A(ccepted) or D(eclined)

  const api = useAxios();
  const [dataFetched, setDataFetched] = useState(false);

  const [addActivityModalState, setAddActivityModalState] = useState(false);

  const navigate = useNavigate();

  const fetchData = async (activityNumbers) => {
    try {
      const id1 = activityNumbers[0];
      const id2 = activityNumbers[1];
      const id3 = activityNumbers[2];
      console.log([id1, id2, id3]);
      setActivityNumbers([id1, id2, id3]);
      if (id1 == null && id2 == null && id3 == null) {
        setNoActivities(true);
      }
      if (id1 == null) {
        setActivity1Visible(false);
      } else {
        setActivity1Visible(true);
        const response1 = await api.get("/activity/" + id1 + "/");
        console.log(response1.data);
        setActivity1Details(response1.data.response);
        const response = await api.get("/registeractivity/" + id1 + "/");
        setRegisterStatus((prev) => prev.with(0, response.data.status));
      }
      if (id2 == null) {
        setActivity2Visible(false);
      } else {
        setActivity2Visible(true);
        const response2 = await api.get("/activity/" + id2 + "/");
        console.log(response2.data);
        setActivity2Details(response2.data.response);
        const response = await api.get("/registeractivity/" + id2 + "/");
        setRegisterStatus((prev) => prev.with(1, response.data.status));
      }
      if (id3 == null) {
        setActivity3Visible(false);
      } else {
        setActivity3Visible(true);
        const response3 = await api.get("/activity/" + id3 + "/");
        console.log(response3.data);
        setActivity3Details(response3.data.response);
        const response = await api.get("/registeractivity/" + id3 + "/");
        setRegisterStatus((prev) => prev.with(2, response.data.status));
      }
    } catch {
      console.log("Something went wrong when fetching activities");
    }
    setDataFetched(true);
  };

  const fetchPage = async (activitiesPerPage) => {
    try {
      console.log("running!");
      const response = await api.get("activitynumbers/" + page + "/", {
        params: { activitiesPerPage: activitiesPerPage },
      });
      const activityNumbers = response.data.response;
      setIsFirstPage(response.data.isFirstPage);
      setIsLastPage(response.data.isLastPage);
      if (
        page === 0 ||
        activityNumbers.every((el) => el != null) ||
        (page === -1 &&
          activityNumbers[0] != null &&
          activityNumbers[1] != null &&
          activityNumbers[2] === null)
      ) {
        fetchData(activityNumbers);
      } else {
        setPage(previousPage);
      }
    } catch {
      console.log(
        "Something went wrong when fetching activityNumbers for this page"
      );
    }
  };

  useEffect(() => {
    var count;
    if (ResponsiveVariables.isDesktop) {
      count = 3;
    } else if (ResponsiveVariables.isTablet) {
      count = 2;
    } else if (ResponsiveVariables.isMobile) {
      count = 1;
    }
    fetchPage(count);
    fetchID();
  }, [page]);

  const toPreviousPage = async () => {
    if (!isFirstPage) {
      setPage(page - 1);
      setPreviousPage(page);
    }
  };

  const toNextPage = async () => {
    if (!isLastPage) {
      setPage(page + 1);
      setPreviousPage(page);
    }
  };

  // get logged in user
  const fetchID = async () => {
    try {
      const response = await api.get("/profile/id/");
      setIsAdmin(response.data.admin);
    } catch {
      console.log("Something went wrong while retreiving id");
    }
  };

  // {ResponsiveVariables.isMobile ? "+" : "+ Add activity"}

  var activityList = [
    {
      visible: activity1Visible,
      number: activityNumbers[0],
      details: activity1Details,
      index: 0,
    },
    {
      visible: activity2Visible,
      number: activityNumbers[1],
      details: activity2Details,
      index: 1,
    },
    {
      visible: activity3Visible,
      number: activityNumbers[2],
      details: activity3Details,
      index: 2,
    },
  ];

  const buttonOutput = (nr) => {
    if (registerStatus[nr] === "R") {
      return (
        <div className="registreerbuttondiv">
          <button className="registreerbutton" type="button">
            → are you coming?
          </button>
        </div>
      );
    }
    if (registerStatus[nr] === "A") {
      return (
        <div className="acceptedbuttondiv">
          <button className="acceptedbutton" type="button">
            Accepted
          </button>
        </div>
      );
    }
    if (registerStatus[nr] === "D") {
      return (
        <div className="declinedbuttondiv">
          <button className="declinedbutton" type="button">
            Declined
          </button>
        </div>
      );
    }
  };

  const openAddActivityModal = () => {
    setAddActivityModalState((addActivityModalState) => !addActivityModalState);
    console.log("switched state!");
  };

  return (
    <div
      className={classNames(ResponsiveVariables.screenClass, "activitypage")}
    >
      {isAdmin ? (
        <button
          onClick={openAddActivityModal}
          className="addactivitybutton"
          type="button"
        >
          {ResponsiveVariables.isMobile ? "+" : "+ Add activity"}
        </button>
      ) : (
        ""
      )}

      <AddActivityModal
        showModal={addActivityModalState}
        setShowModal={setAddActivityModalState}
        // data={res}
        // id={id}
        fetchData={fetchData}
      />
      <button className="upward-triangle" onClick={toPreviousPage}>
        {isFirstPage ? backwardIconGray : backwardIconBlue}
      </button>
      <div
        className={classNames(ResponsiveVariables.screenClass, "activity-list")}
      >
        {dataFetched &&
          activityList
            .filter((item) => item.visible === true)
            .map((item, index) => (
              <div
                className={classNames(
                  ResponsiveVariables.screenClass,
                  "activity-listitem",
                  {
                    "grayed-out": PastStartDate(
                      new Date(item.details.start_date)
                    ),
                  }
                )}
                onClick={() => navigate("/activityDetail/" + item.number)}
              >
                <div className="calendaricon-parent">
                  <div className="calendaricon">
                    <CalendarIcon date={new Date(item.details.start_date)} />
                  </div>
                </div>

                <div className="activity-listitem-photo">
                  <img
                    id="beerandpizza"
                    src={
                      item.details.image
                        ? window._env_.REACT_APP_API_URL + item.details.image
                        : BeerAndPizza
                    }
                    alt="Beer & Pizza"
                  ></img>
                </div>

                <div className="activity-listitem-data">
                  <h1>
                    <b>{item.details.title}</b>
                  </h1>
                  <br></br>
                  {compareTwoDatesEqual(
                    new Date(item.details.start_date),
                    new Date(item.details.end_date)
                  ) && (
                    <p>
                      <div className="activity-listitem-data-icon">
                        {clockIcon}
                        <div>
                          {formatDate(
                            item.details.start_date,
                            "numeric",
                            "short",
                            "numeric"
                          )}{" "}
                          {formatTime(item.details.start_date)}
                          &nbsp;- {formatTime(item.details.end_date)}
                        </div>
                      </div>
                    </p>
                  )}
                  {!compareTwoDatesEqual(
                    new Date(item.details.start_date),
                    new Date(item.details.end_date)
                  ) && (
                    <p>
                      <div className="activity-listitem-data-icon">
                        {clockIcon}
                        <div>
                          {formatDate(
                            item.details.start_date,
                            "numeric",
                            "short",
                            "numeric"
                          )}{" "}
                          {formatTime(item.details.start_date)}
                          &nbsp;-> <br></br>
                          {formatDate(
                            item.details.end_date,
                            "numeric",
                            "short",
                            "numeric"
                          )}{" "}
                          {formatTime(item.details.end_date)}
                        </div>
                      </div>
                    </p>
                  )}
                  <br></br>
                  {/* <p>{item.details.description}</p>
                  <br></br> */}
                  <div className="activity-listitem-data-icon">
                    {addresslIcon}
                    <div>{item.details.location}</div>
                  </div>
                  <br></br>
                  <i>organised by: {item.details.organizer}</i>
                </div>

                <div className="activity-listitem-button">
                  {buttonOutput(item.index)}
                  {/* <button id="registreerbutton" type="button">
                    Registreer nu!
                  </button> */}
                </div>
              </div>
            ))}
      </div>
      <button className="downward-triangle" onClick={toNextPage}>
        {isLastPage ? forwardIconGray : forwardIconBlue}
      </button>
    </div>
  );
}

export default ActivitiesPage;
