import React from "react";
import "./ICalEvent.css";
import useAxios from "../utils/useAxios";
import { useEffect, useState } from "react";

export const ICalEvent = ({
  title,
  description,
  location,
  startdate,
  enddate,
}) => {
  const state = {
    event: {
      title: title,
      description: description,
      location: location,
      startDate: startdate,
      endDate: enddate,
    },
  };
  console.log(state);
  const [organizationName, setOrganizationName] = useState("");
  const api = useAxios();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await api.get("/settings/");
        console.log("Retrieving general settings");
        console.log(response.data);
        setOrganizationName(response.data.organization_name);
      } catch {
        console.log("Something went wrong while fetching settings");
      }
    };

    fetchSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveCalInvite = (state) => {
    const formatDate = (dateString) => {
      let dateTime = new Date(dateString);
      const pad = (num) => {
        // Ensure date values are double digits
        return num < 10 ? "0" + num : num;
      };
      return [
        dateTime.getUTCFullYear(),
        pad(dateTime.getUTCMonth() + 1),
        pad(dateTime.getUTCDate()),
        "T",
        pad(dateTime.getUTCHours()),
        pad(dateTime.getUTCMinutes()) + "00Z",
      ].join("");
    };

    // Create the .ics URL
    let url = [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "PRODID:-//QUORUM//" + organizationName + "//" + state.event.title,
      "BEGIN:VEVENT",
      "UID:" + state.event.title + formatDate(state.event.startDate) + "123",
      "DTSTART:" + formatDate(state.event.startDate),
      "DTEND:" + formatDate(state.event.endDate),
      "DTSTAMP:" + formatDate(state.event.startDate),
      "SUMMARY:" + state.event.title,
      "DESCRIPTION:" + state.event.description,
      "LOCATION:" + state.event.location,
      "BEGIN:VALARM",
      "TRIGGER:-PT15M",
      "REPEAT:1",
      "DURATION:PT15M",
      "ACTION:DISPLAY",
      "DESCRIPTION:Reminder",
      "END:VALARM",
      "END:VEVENT",
      "END:VCALENDAR",
    ].join("\r\n");

    let filename = title;

    let blob = new Blob([url], { type: "text/calendar;charset=utf-8" });
    console.log(blob);
    if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
      // Open/Save link in IE and Edge
      window.navigator.msSaveOrOpenBlob(
        blob,
        "".concat(filename || "download", ".ics")
      );
    } else {
      // Open/Save link in Modern Browsers
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", filename);
      link.setAttribute("download", "".concat(filename || "download", ".ics"));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <button onClick={() => saveCalInvite(state)} className="icalbutton">
      Add to Calendar
    </button>
  );
};

export default ICalEvent;
