import { useEffect, useState, useRef, useCallback } from "react";
import useAxios from "../utils/useAxios";
import { useParams, useNavigate } from "react-router-dom";
import "./userDetail.css";
import getCroppedImg from "../utils/cropImage";
import Cropper from "react-easy-crop";
import UserDetailEditModal from "./UserDetailEditModal";
import {
  compareTwoDatesEqual,
  formatDate,
  formatTime,
} from "../utils/DateFunctions";
import { LetteredAvatar } from "../utils/LetteredAvatarFunction";
import ReactGA from "react-ga4";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faCakeCandles } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faUserPen } from "@fortawesome/free-solid-svg-icons";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const mailIcon = (
  <FontAwesomeIcon icon={faEnvelope} style={{ color: "#2f96ff" }} />
);
const phoneIcon = (
  <FontAwesomeIcon icon={faPhone} style={{ color: "#2f96ff" }} />
);
const addresslIcon = (
  <FontAwesomeIcon icon={faLocationDot} style={{ color: "#2f96ff" }} />
);
const dobIcon = (
  <FontAwesomeIcon icon={faCakeCandles} style={{ color: "#2f96ff" }} />
);
const jobIcon = (
  <FontAwesomeIcon icon={faBriefcase} style={{ color: "#2f96ff" }} />
);
const roleIcon = (
  <FontAwesomeIcon icon={faUserPen} style={{ color: "#2f96ff" }} />
);
const languageIcon = (
  <FontAwesomeIcon icon={faFlag} style={{ color: "#2f96ff" }} />
);
const registerIcon = (
  <FontAwesomeIcon icon={faPencil} style={{ color: "#2f96ff" }} />
);

const editIcon = <FontAwesomeIcon icon={faPencil} className="edit-icon" />;
const backIcon = <FontAwesomeIcon icon={faChevronLeft} className="back-icon" />;

const UserDetail = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/userdetail",
    title: "User Detail",
  });
  const { id } = useParams();
  const [res, setRes] = useState("");
  const [dateJoined, setDateJoined] = useState("");
  const [email, setEmail] = useState("");
  const [avatar, setAvatar] = useState("");
  const [modalState, setModalState] = useState(false);
  const api = useAxios();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [editModalState, setEditModalState] = useState(false);
  const [userID, setUserID] = useState(0);
  const [userName, setUserName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const showHideClassName = modalState
    ? "modal display-block"
    : "modal display-none";

  useEffect(() => {
    console.log("useeffect!");

    console.log(id);
    fetchData();
    fetchID();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchData = async () => {
    try {
      const response = await api.get("/user/" + id);
      console.log("TEST");
      console.log(response.data.response);
      console.log(response.data.date_joined);
      setRes(response.data.response);
      if (response.data.response.avatar) {
        setAvatar(
          window._env_.REACT_APP_API_URL + response.data.response.avatar
        );
      } else {
        setAvatar("");
      }
      setDateJoined(response.data.date_joined);
      setEmail(response.data.email);
    } catch {
      console.log("Something went wrong while retreiving user");
    }
  };

  // get logged in user
  const fetchID = async () => {
    try {
      const response = await api.get("/profile/id/");
      console.log(response.data);
      console.log(response.data.id);
      setUserID(response.data.id);
      setUserName(response.data.name);
      setIsAdmin(response.data.admin);
    } catch {
      console.log("Something went wrong while retreiving id");
    }
  };

  const handleFileChange = (event) => {
    console.log(event.target.files[0].name);
    setSelectedImage(event.target.files[0]);
    setSelectedImageURL(URL.createObjectURL(event.target.files[0]));
    showModal();
  };

  const handleFileUpload = async (event) => {
    showCroppedImage();
    hideModal();
  };

  const showModal = () => {
    setModalState(true);
    console.log(selectedImage);
  };

  const hideModal = () => {
    setModalState(false);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    console.log(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        selectedImageURL,
        croppedAreaPixels,
        0
      );
      console.log("donee", { croppedImage });
      let data = new FormData();
      data.append("file", croppedImage, "avatar123.jpg");
      const response = await api.post("/user/" + id + "/avatar/", data);
      console.log(response.data);
      setAvatar(window._env_.REACT_APP_API_URL + response.data);
    } catch (e) {
      console.error(e);
    }
    window.location.reload();
  }, [selectedImageURL, croppedAreaPixels]);

  const openEditModal = () => {
    setEditModalState((editModalState) => !editModalState);
  };
  return (
    <div
      className={classNames(
        ResponsiveVariables.screenClass,
        "parentuserdetail"
      )}
    >
      <div
        className={classNames(ResponsiveVariables.screenClass, "userdetail")}
      >
        <div className="profilebanner">
          <button
            className={classNames(
              ResponsiveVariables.screenClass,
              "back-button"
            )}
            onClick={() => navigate(-1)}
          >
            {backIcon}
          </button>
          <div className="profilepicture">
            {avatar ? (
              <img src={avatar} alt="" className="avatar" />
            ) : (
              <LetteredAvatar name={res.name + " " + res.surname} />
            )}
            <input
              ref={inputRef}
              onChange={handleFileChange}
              type="file"
              style={{ display: "none" }}
              // multiple={false}
            />
            {Number(id) === userID || isAdmin ? (
              <button onClick={() => inputRef.current.click()}>
                Upload File
              </button>
            ) : (
              ""
            )}
            <div className={showHideClassName}>
              <section className="modal-main">
                <p align="center">Modal</p>
                <div className="cropper">
                  {selectedImageURL && (
                    <Cropper
                      image={selectedImageURL}
                      crop={crop}
                      aspect={5 / 5}
                      zoom={zoom}
                      zoomSpeed={4}
                      maxZoom={3}
                      zoomWithScroll={true}
                      showGrid={true}
                      cropShape="round"
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  )}
                </div>
                <button onClick={handleFileUpload}>Confirm!</button>
                <button type="button" onClick={hideModal}>
                  Close
                </button>
              </section>
            </div>
          </div>
          <div className="profilename">
            <h1>
              {res.sex ? "Mrs. " : "Mr. "}
              {res.name} {res.surname}
            </h1>
          </div>

          {Number(id) === userID || isAdmin ? (
            <button className="edit-button" onClick={openEditModal}>
              {editIcon}
            </button>
          ) : (
            ""
          )}
          <UserDetailEditModal
            showModal={editModalState}
            setShowModal={setEditModalState}
            data={res}
            email={email}
            id={id}
            fetchData={fetchData}
          />
        </div>
        <div className="profilecontent">
          <div className="leftcontent">
            <div className="contentelement">
              {mailIcon}
              <p>{email}</p>
            </div>

            <div className="contentelement">
              {phoneIcon}
              <p>{res.tel}</p>
            </div>
            <div className="contentelement">
              {addresslIcon}
              <p>
                {res.street} {res.streetNumber}, {res.zipCode} {res.place}
              </p>
            </div>
            <div className="contentelement">
              {dobIcon}

              <p>
                {formatDate(res.dateofBirth, "numeric", "short", "numeric")}
              </p>
            </div>
          </div>
          <div className="rightcontent">
            <div className="contentelement">
              {jobIcon}
              <p>{res.function}</p>
            </div>
            <div className="contentelement">
              {roleIcon}
              <p>{res.admin ? "Admin" : "Member"}</p>
            </div>
            <div className="contentelement">
              {languageIcon}
              <p>
                {res.language === "nl"
                  ? "Dutch"
                  : res.language === "en"
                  ? "English"
                  : res.language === "fr"
                  ? "French"
                  : res.language}
              </p>
            </div>
            <div className="contentelement">
              {registerIcon}
              <p>{formatDate(dateJoined)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
