import { useEffect, useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import "./NavbarMobile.css";
import { Breakpoint } from "react-socks";
import ClientLogo from "../components/systemGeneralComponents/clientLogo.js";
import useAxios from "../utils/useAxios";
import { LetteredAvatar } from "../utils/LetteredAvatarFunction";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

const Navbar = () => {
  const { user, logoutUser } = useContext(AuthContext);
  const [checked, setChecked] = useState(false);

  const [avatar, setAvatar] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const api = useAxios();

  useEffect(() => {
    const fetchAvatar = async () => {
      console.log("called!");
      try {
        const response = await api.get("/profile/avatar/");
        console.log("TEST");
        console.log(response.data);
        if (response.data) {
          setAvatar(window._env_.REACT_APP_API_URL + response.data);
        } else {
          setAvatar("");
        }
      } catch {
        console.log("Something went wrong while retreiving avatar");
      }
    };
    fetchAvatar();
    const fetchID = async () => {
      try {
        const response = await api.get("/profile/id/");
        console.log(response.data);
        setId(response.data.id);
        setName(response.data.name);
        setSurname(response.data.surname);
      } catch {
        console.log("Something went wrong while retreiving id");
      }
    };
    fetchID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <nav className={classNames(ResponsiveVariables.screenClass, "nav-mob")}>
      <input
        type="checkbox"
        checked={checked}
        onClick={() => {
          setChecked((old) => !old);
        }}
        className="toggler"
      ></input>
      <div className="hamburger">
        <div></div>
      </div>
      <div className="menu">
        <Link to="/" className="nav-item-tilte-bar-logo">
          <ClientLogo width="40px" height="40px" display="flex" />
          <p>QUORUM</p>
        </Link>
        <div className="menuitems-mob">
          {user ? (
            <ul className="menuitem-mob">
              <Link
                onClick={() => {
                  setChecked((old) => !old);
                }}
                to="/dashboard"
                className="link-mob"
              >
                Dashboard
              </Link>
              <br></br>
              <br></br>
              <Link
                onClick={() => {
                  setChecked((old) => !old);
                }}
                to="/activities"
                className="link-mob"
              >
                Activities
              </Link>
              <br></br>
              <br></br>
              <Link
                onClick={() => {
                  setChecked((old) => !old);
                }}
                to="/users"
                className="link-mob"
              >
                Users
              </Link>
              <br></br>
              <br></br>
              <Link
                onClick={() => {
                  setChecked((old) => !old);
                }}
                to={"/user/" + id + "/"}
                className="link-mob"
              >
                {avatar ? (
                  <img src={avatar} className="avatar" />
                ) : (
                  <LetteredAvatar
                    name={name + " " + surname}
                    size="60px"
                    fontsize="24px"
                  />
                )}
              </Link>

              <button onClick={logoutUser} className="logout-button">
                Logout
              </button>
            </ul>
          ) : null}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
