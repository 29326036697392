import { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import "./registerPage.css";
import ClientLogo from "../components/systemGeneralComponents/clientLogo.js";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [backendErrors, setBackendErrors] = useState([]);
  const [completed, setCompleted] = useState(false);
  const { registerUser } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const urlEmail = searchParams.get("email");
    console.log(urlEmail);
    if (urlEmail) {
      setEmail(urlEmail);
    }
  }, []);

  const gatherResult = async () => {
    const result = await registerUser(email, password, password2);
    console.log(result);
    if (result) {
      setCompleted(result.registration_success);
      if (!result.registration_success) {
        console.log(result.error);
        setBackendErrors(result.error.password);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    gatherResult();
  };

  return (
    <section
      className={classNames(ResponsiveVariables.screenClass, "register")}
      id="register"
    >
      {completed ? (
        <>
          <h1 className="resetpassword_text">
            We sent an email to verify your email address.
          </h1>
          <h1 className="resetpassword_text">Please check your mailbox.</h1>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className={classNames(ResponsiveVariables.screenClass, "head")}>
            <ClientLogo width="100px" height="100px" display="flex" />
            <h1 className="company">Register</h1>
          </div>
          <input
            type="text"
            id="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-Mail"
            required
            className="text"
            value={email}
          />
          <input
            type="password"
            id="password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
            className="password"
          />
          <input
            type="password"
            id="confirm-password"
            name="password"
            onChange={(e) => setPassword2(e.target.value)}
            placeholder="Confirm Password"
            required
            className="password"
          />
          <p>{password2 !== password ? "Passwords do not match" : ""}</p>
          <p className="form-validation-error">
            {backendErrors && backendErrors.map((e) => <p>{e}</p>)}
          </p>
          <button type="submit" className="btn-register">
            Register
          </button>
        </form>
      )}
    </section>
  );
}

export default Register;
