import "./Settings.css";
import { useEffect, useState, useRef, useCallback } from "react";
import useAxios from "../utils/useAxios";
import ClientLogo from "../components/systemGeneralComponents/clientLogo.js";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import getCroppedImg from "../utils/cropImage";
import Cropper from "react-easy-crop";
import ReactGA from "react-ga4";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

const Settings = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/settings",
    title: "settings",
  });

  const [logo, setLogo] = useState("");
  const api = useAxios();
  const baseURL = window._env_.REACT_APP_API_URL + "/api";

  const inputRef = useRef(null);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await api.get("/settings/logo/");
        setLogo(window._env_.REACT_APP_API_URL + response.data);
      } catch {
        console.log("Something went wrong while retreiving logo");
      }
    };

    const fetchSettings = async () => {
      try {
        const response = await api.get("/settings/");
        console.log("Retrieving general settings");
        console.log(response.data);
        setValue("organization_name", response.data.organization_name);
        setValue("established_year", response.data.established_year);
        setValue(
          "member_profile_visible",
          response.data.member_profiles_visible
        );
      } catch {
        console.log("Something went wrong while fetching settings");
      }
    };

    fetchLogo();
    fetchSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileChange = async (event) => {
    console.log("CHANGE");
    console.log(event.target.files[0].name);
    const selectedImage = event.target.files[0];
    const selectedImageURL = URL.createObjectURL(selectedImage);

    console.log("UPLOAD");
    try {
      let data = new FormData();
      console.log(selectedImageURL);
      console.log(selectedImage);
      data.append("file", selectedImage, selectedImage.name);
      const response = await api.post("/settings/logo/", data);
      console.log(response.data);
      setLogo(window._env_.REACT_APP_API_URL + response.data);
    } catch (e) {
      console.error(e);
    }
    window.location.reload();
  };

  const onSubmit = (data, event) => {
    console.log(data);
    updateSettings(data);
  };

  const updateSettings = async (data) => {
    try {
      const response = await api.post(baseURL + "/settings/", {
        organization_name: data.organization_name,
        established_year: data.established_year,
        member_profiles_visible: data.member_profile_visible,
      });
    } catch {
      console.log("Something went wrong while updating organization name.");
    }
  };

  const handleYearEstablishedChange = (event) => {
    setValue("established_year", event.target.value);
  };

  const handleOrganizationNameChange = (event) => {
    setValue("organization_name", event.target.value);
  };

  const handleMemberProfileVisibleChange = (event) => {
    setValue("member_profile_visible", event.target.checked);
  };

  // Form validation
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  return (
    <div
      className={classNames(ResponsiveVariables.screenClass, "settings-parent")}
    >
      <div className="settings-body">
        <h1>Settings</h1>
        <div className="profilepicture">
          <h3>Logo</h3>
          {logo ? (
            <img src={logo} alt="" className="logo" />
          ) : (
            <p>No logo avilable</p>
          )}
          <input
            className="settings-choose"
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
            type="file"
          />
          <button onClick={() => inputRef.current.click()} type="button">
            Upload new logo
          </button>
        </div>
        <div className="settings-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3>General</h3>
            <div className="pair">
              <div className=" pair-title">
                <p>Organisation name:</p>
              </div>
              <div className="pair-input">
                <input
                  {...register("organization_name", {
                    required: "This field is required.",
                    maxLength: {
                      value: 35,
                      message:
                        "Use an organization name with less than 35 characters.",
                    },
                  })}
                  type="text"
                  id="organization_name"
                  name="organization_name"
                  placeholder="Organisation name"
                  className="text"
                  // value={organizationName}
                  onChange={handleOrganizationNameChange}
                />
              </div>
            </div>
            <div className="pair">
              <div className=" pair-title">
                <p>Year established:</p>
              </div>
              <input
                {...register("established_year", {
                  required: "This field is required.",
                  pattern: {
                    value: /^[12][0-9]{3}$/,
                    message: "Use an integer value of 4 digits.",
                  },
                })}
                type="text"
                id="established_year"
                placeholder="Year established"
                className="text"
                // value={yearEstablished}
                onChange={handleYearEstablishedChange}
              />
            </div>
            <div className="pair">
              <div className=" pair-title">
                <p>Members can see other members?</p>
              </div>
              <input
                {...register("member_profile_visible")}
                type="checkbox"
                id="member_profile_visible"
                className="text larger-checkbox"
                //checked={memberProfileVisible}
                onChange={handleMemberProfileVisibleChange}
              />
            </div>
            <button type="submit">Save</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Settings;
