import { useState, useRef } from "react";
import useAxios from "../utils/useAxios";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import "./AddActivityModal.css";
import Modal from "@mui/material/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

const deleteIcon = (
  <FontAwesomeIcon icon={faTrashCan} className="delete-icon" />
);

const AddActivityModal = ({ showModal, setShowModal, fetchData }) => {
  const api = useAxios();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const inputRef = useRef(null);

  const [formData, setFormData] = useState({
    title: "",
    start_date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    end_date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    location: "",
    organizer: "",
    description: "",
    published: false,
    image: "",
    form: '{"form": []}',
  });

  const handleClose = () => {
    console.log("closing!");
    setShowModal(false);
  };

  const onSubmit = (event) => {
    addActivity();
    setShowModal(false);
    // window.location.reload();
  };

  const addActivity = async () => {
    console.log("creating activity!");
    let url = window._env_.REACT_APP_API_URL + "/api/createactivity/";
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    api
      .post(url, formData, config)
      .then((res) => {
        console.log(res.request.status);
        console.log(res.data);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const handleTitleChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      title: event.target.value,
    }));
  };

  const handleStartDateChange = (value) => {
    console.log("dit is het event:");
    console.log(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      start_date: value.format("YYYY-MM-DD HH:mm:ss"),
    }));
  };

  const isDateInFuture = (date) => {
    if (!date) {
      return false;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    console.log("CHECKING!");

    if (date > today) {
      console.log("TREU");
      return true;
    } else {
      console.log("FLASE");
      return "Event date is not in the future";
    }
  };

  const isStartDateBeforeEndDate = (date) => {
    return true;
    // if (!date) {
    //   return false;
    // }
    // console.log(date);
    // console.log(formData.end_date);
    // if (date <= formData.end_date) {
    //   return true;
    // } else {
    //   console.log("ERROR! ");
    //   console.log(date);
    //   console.log(formData.end_date);
    //   return "Event start date should be before end date";
    // }
  };

  const isEndDateAfterStartDate = (date) => {
    return true;
    // if (!date) {
    //   return false;
    // }
    // if (formData.start_date <= formData.end_date) {
    //   return true;
    // } else {
    //   console.log("ERROR! ");
    //   console.log(date);
    //   console.log(formData.start_date);
    //   return "Event end date should be after start date";
    // }
  };

  const handleEndDateChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      end_date: value.format("YYYY-MM-DD HH:mm:ss"),
    }));
  };
  const handleLocationChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: event.target.value,
    }));
  };
  const handleOrganizerChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      organizer: event.target.value,
    }));
  };
  const handleDescriptionChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      description: event.target.value,
    }));
  };
  const handlePublishedChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      published: event.target.checked,
    }));
  };
  const handleImageChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: event.target.files[0],
    }));
  };

  const addQuestionToForm = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      form: JSON.stringify({
        form: [
          ...JSON.parse(prevFormData.form).form,
          {
            type: "Multiple-choice",
            question: "",
            answers: ["", ""],
          },
        ],
      }),
    }));
  };

  const handleQuestionChange = (questionIndex, value) => {
    let newform = formData.form;
    const json = JSON.parse(newform);
    json.form[questionIndex].question = value;
    newform = JSON.stringify(json);
    setFormData((prevFormData) => ({
      ...prevFormData,
      form: newform,
    }));
  };

  const handleAnswerChange = (questionIndex, answerIndex, value) => {
    let newform = formData.form;
    const json = JSON.parse(newform);
    json.form[questionIndex].answers[answerIndex] = value;
    newform = JSON.stringify(json);
    setFormData((prevFormData) => ({
      ...prevFormData,
      form: newform,
    }));
    console.log("PRINT");
    console.log(formData.form);
  };

  const deleteQuestion = (questionIndex) => {
    let newform = formData.form;
    const json = JSON.parse(newform);
    json.form.splice(questionIndex, 1);
    newform = JSON.stringify(json);
    setFormData((prevFormData) => ({
      ...prevFormData,
      form: newform,
    }));
  };

  return (
    showModal && (
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classNames(
          ResponsiveVariables.screenClass,
          "add-activity-modal"
        )}
      >
        <div
          className={classNames(
            ResponsiveVariables.screenClass,
            "add-activity-modal-body"
          )}
        >
          <div className={classNames(ResponsiveVariables.screenClass, "title")}>
            <h1>Add Activity</h1>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classNames(
              ResponsiveVariables.screenClass,
              "add-activity-form"
            )}
            noValidate
          >
            <div className="form-group">
              <label htmlFor="title">Title </label>
              <input
                {...register("title", { required: "This field is required" })}
                className="form-control"
                id="title"
                placeholder="Title"
                value={formData.title}
                onChange={handleTitleChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.title && errors.title.message}
            </p>
            <div className="form-group">
              <label htmlFor="">Description </label>
              <textarea
                {...register("description", {
                  required: "This field is required",
                })}
                className="form-control"
                rows="10"
                id="description"
                placeholder="Description..."
                value={formData.description}
                onChange={handleDescriptionChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.description && errors.description.message}
            </p>
            <br></br>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Start date"
                  value={new Date(formData.start_date)}
                  inputFormat="DD-MM-YYYY HH:mm"
                  onChange={handleStartDateChange}
                  ampm={false}
                  // views={["day", "hours", "minutes"]}
                />
              </LocalizationProvider>
              <input
                {...register("start_date", {
                  required: "This field is required",
                  validate: { isDateInFuture },
                  validate: { isStartDateBeforeEndDate },
                })}
                id="start_date"
                hidden={true}
                value={formData.start_date}
              />
            </div>
            <p className="form-validation-error">
              {errors.start_date && errors.start_date.message}
            </p>
            <br></br>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="End date"
                  value={new Date(formData.end_date)}
                  inputFormat="DD-MM-YYYY HH:mm"
                  onChange={handleEndDateChange}
                  ampm={false}
                  // views={["day", "hours", "minutes"]}
                />
              </LocalizationProvider>
              <input
                {...register("end_date", {
                  required: "This field is required",
                  validate: { isDateInFuture },
                  validate: { isEndDateAfterStartDate },
                })}
                id="end_date"
                hidden={true}
                value={formData.end_date}
              />
            </div>
            <p className="form-validation-error">
              {errors.end_date && errors.end_date.message}
            </p>
            <div className="form-group">
              <label htmlFor="place">Place </label>
              <input
                {...register("location", {
                  required: "This field is required",
                })}
                className="form-control"
                id="location"
                placeholder="Place"
                value={formData.location}
                onChange={handleLocationChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.location && errors.location.message}
            </p>
            <div className="form-group">
              <label htmlFor="organizer">Organizer </label>
              <input
                {...register("organizer", {
                  required: "This field is required",
                })}
                className="form-control"
                id="organizer"
                placeholder="Organizer"
                value={formData.organizer}
                onChange={handleOrganizerChange}
              />
            </div>
            <p className="form-validation-error">
              {errors.organizer && errors.organizer.message}
            </p>
            <div className="form-group">
              <div className="pair">
                <label htmlFor="published">
                  Publish so members can see activity{" "}
                </label>
                <input
                  {...register("published", {})}
                  className="form-control"
                  id="published"
                  checked={formData.published}
                  onChange={handlePublishedChange}
                  type="checkbox"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="pair">
                <label htmlFor="foto">Foto </label>
                <input
                  className="form-control"
                  id="image"
                  // value={formData.image}
                  onChange={handleImageChange}
                  type="file"
                  // style={{ display: "none" }}
                  ref={inputRef}
                />
                {/* <button onClick={() => inputRef.current.click()} type="button">
                  Upload File
                </button> */}
              </div>
            </div>
            <div className="form-group">
              <div className="custom-form">
                <label htmlFor="Questions">Questions</label>
                {JSON.parse(formData.form).form.map(
                  (formComponent, questionIndex) => {
                    return (
                      <div key={"question-div" + questionIndex}>
                        <hr key={"question-hr" + questionIndex}></hr>
                        <div
                          className="pair"
                          key={"question-pair" + questionIndex}
                        >
                          <p
                            className="custom-form-question-id"
                            key={"question-id" + questionIndex}
                          >
                            {questionIndex + 1}.
                          </p>
                          <input
                            key={"question" + questionIndex}
                            {...register("question" + String(questionIndex), {
                              required: "This field is required",
                            })}
                            className="form-control custom-form-input"
                            placeholder="Question"
                            value={formComponent.question}
                            onChange={(e) =>
                              handleQuestionChange(
                                questionIndex,
                                e.target.value
                              )
                            }
                          />
                          <button
                            className={classNames(
                              ResponsiveVariables.screenClass,
                              "delete-button"
                            )}
                            onClick={() => deleteQuestion(questionIndex)}
                            type="button"
                          >
                            {deleteIcon}
                          </button>
                        </div>
                        <div
                          className="custom-form-answers"
                          key={"answers" + questionIndex}
                        >
                          <p
                            className="custom-form-answer-title"
                            key={"answer-title" + questionIndex}
                          >
                            Possible answers
                          </p>
                          {formComponent.answers.map((answer, answerIndex) => (
                            <div
                              className="pair"
                              key={"answer-pair" + questionIndex + answerIndex}
                            >
                              <p
                                className="custom-form-answer-id"
                                key={"answer-id" + questionIndex + answerIndex}
                              >
                                {(answerIndex + 1 + 9).toString(36)}.
                              </p>
                              <input
                                key={"answer" + questionIndex + answerIndex}
                                {...register(
                                  "answer" +
                                    String(questionIndex) +
                                    String(answerIndex),
                                  {
                                    required: "This field is required",
                                  }
                                )}
                                className="form-control custom-form-answer-input"
                                placeholder="Answer"
                                value={answer}
                                onChange={(e) =>
                                  handleAnswerChange(
                                    questionIndex,
                                    answerIndex,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  }
                )}
                <button
                  className="add-question-button"
                  onClick={addQuestionToForm}
                  type="button"
                >
                  Add question
                </button>
              </div>
            </div>

            <div className="form-buttons">
              <button className="form-control btn btn-primary" type="submit">
                Save
              </button>
              <button
                className="form-control btn btn-secundary cancelbutton"
                onClick={handleClose}
                type="button"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    )
  );
};

export default AddActivityModal;
