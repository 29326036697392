import React from "react";
import Logo from "../../img/systemGeneral/QVLogo.svg";
import { useEffect, useState, useRef, useCallback } from "react";
import useAxios from "../../utils/useAxios";

function MainLogo(props) {
  const api = useAxios();
  const [logo, setLogo] = useState("../../img/systemGeneral/QVLogo.svg");
  const fetchLogo = async () => {
    try {
      // const response = await api.get("/settings/logo/");
      const response = await fetch(
        window._env_.REACT_APP_API_URL + "/api/settings/logo/",
        {
          method: "GET",
        }
      );
      const data = await response.json();
      console.log(data);
      setLogo(window._env_.REACT_APP_API_URL + data);
    } catch {
      console.log("Something went wrong while retreiving logo");
    }
  };

  useEffect(() => {
    fetchLogo();
  }, []);

  return (
    <img
      src={logo}
      alt="React Logo"
      width={props.width}
      height={props.height}
      className={props.className}
      // style={{ backgroundColor: "yellow" }}
    />
  );
}

export default MainLogo;
