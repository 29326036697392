import { useEffect, useState } from "react";
// import useAxios from "../utils/useAxios";
import "./ResetPasswordPage.css";
import ClientLogo from "../components/systemGeneralComponents/clientLogo.js";
import { useParams, useNavigate } from "react-router-dom";
// import AuthContext from "../context/AuthContext";
import axios from "axios";
import { useForm } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global.js";

const eye = <FontAwesomeIcon icon={faEye} size="1x" />;

function ResetPassword() {
  const { uid, token } = useParams();
  const [backendErrors, setBackendErrors] = useState([]);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [email, setEmail] = useState("");
  const baseURL = window._env_.REACT_APP_API_URL + "/api";

  const getEmail = async () => {
    try {
      const response = await axios.get(baseURL + "/uidtouser/" + uid);
      console.log(response.data.email);
      setEmail(response.data.email);
    } catch {
      console.log("Something went wrong while resetting password");
    }
  };

  useEffect(() => {
    console.log("useeffect!");
    console.log(uid);
    console.log(token);
    getEmail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const onSubmit = (data, event) => {
    console.log(data);
    resetPassword();

    // setCompleted(true);
  };

  const resetPassword = async () => {
    try {
      const response = await axios.post(
        baseURL + "/passwordreset/" + uid + "/" + token,
        {
          password: password,
        }
      );
      if (response.data.password_reset_success) {
        console.log(response.data);
        navigate(`/`, { state: { email: response.data.email } });
      } else {
        setBackendErrors(response.data.error);
      }
    } catch {
      console.log("Something went wrong while resetting password");
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handlePasswordChange = (event) => {
    console.log("Event:");
    console.log(event);
    console.log(event.target.value);
    setPassword(event.target.value);
  };

  // Form validation
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <section
      className={classNames(ResponsiveVariables.screenClass, "resetpassword")}
      id="resetpassword"
    >
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <div className={classNames(ResponsiveVariables.screenClass, "head")}>
          <ClientLogo width="100px" height="100px" display="flex" />
          <h1 className="resetpassword_text">
            Please fill in a new password for:
          </h1>
        </div>
        <input
          /* {...register("email", {
            required: "This field is required.",
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Please enter a valid email address.",
            },
            maxLength: {
              value: 150,
              message: "Use an email address with less than 150 characters.",
            },
          })} */
          type="text"
          id="emailpasswordreset"
          placeholder="E-Mail"
          className="text"
          value={email}
          name="emailpasswordreset"
          tabindex="-1"

          // onChange={handleEmailChange}
        />

        <p className="form-validation-error">
          {errors && errors.email && errors.email.message}
        </p>
        <div className="pass-wrapper">
          <input
            {...register("password", {
              required: "This field is required.",
            })}
            id="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="New Password"
            required
            className={passwordShown ? "text" : "password"}
            type={passwordShown ? "text" : "password"}
          />
          <i onClick={togglePasswordVisiblity}>{eye}</i>{" "}
        </div>
        <div className="pass-wrapper">
          <input
            {...register("password2", {
              required: "This field is required.",
            })}
            id="confirm-password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            placeholder="Confirm New Password"
            required
            className={passwordShown ? "text" : "password"}
            type={passwordShown ? "text" : "password"}
          />
          <i onClick={togglePasswordVisiblity}>{eye}</i>{" "}
        </div>
        <p>{password2 !== password ? "Passwords do not match" : ""}</p>
        <p className="form-validation-error">
          {errors.password && errors.password.message}
        </p>
        <p className="form-validation-error">
          {backendErrors.map((e) => (
            <p>{e}</p>
          ))}
        </p>
        <button type="submit" className="btn-resetpassword">
          Reset password
        </button>
      </form>
    </section>
  );
}

export default ResetPassword;
