const { Resource } = require("@opentelemetry/resources");

const {
  SemanticResourceAttributes,
} = require("@opentelemetry/semantic-conventions");

const {
  WebTracerProvider,
  SimpleSpanProcessor,
  ConsoleSpanExporter,
} = require("@opentelemetry/sdk-trace-web");

const {
  OTLPTraceExporter,
} = require("@opentelemetry/exporter-trace-otlp-http");

const { registerInstrumentations } = require("@opentelemetry/instrumentation");

const {
  XMLHttpRequestInstrumentation,
} = require("@opentelemetry/instrumentation-xml-http-request");

const {
  FetchInstrumentation,
} = require("@opentelemetry/instrumentation-fetch");

const consoleExporter = new ConsoleSpanExporter();

const collectorExporter = new OTLPTraceExporter({
  headers: {},
  url: "https://collector.quorum.be/v1/traces",
});

const provider = new WebTracerProvider({
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: window._env_.REACT_APP_API_URL,
  }),
});

const xmlHttpRequestInstrumentation = new XMLHttpRequestInstrumentation({
  propagateTraceHeaderCorsUrls: ["http://localhost:8000"],
});
const fetchInstrumentation = new FetchInstrumentation({});

fetchInstrumentation.setTracerProvider(provider);
xmlHttpRequestInstrumentation.setTracerProvider(provider);

provider.addSpanProcessor(new SimpleSpanProcessor(consoleExporter));

provider.addSpanProcessor(new SimpleSpanProcessor(collectorExporter));

provider.register();

registerInstrumentations({
  instrumentations: [fetchInstrumentation, xmlHttpRequestInstrumentation],

  tracerProvider: provider,
});

export default function TraceProvider({ children }) {
  return <>{children}</>;
}
