import React from "react";
import Modal from "@mui/material/Modal";
import "./ActivityDetailDeleteModal.css";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

export const ActivityDetailDeleteModal = ({
  showModalActivityDelete,
  setShowModalActivityDelete,
  deleteActivity,
}) => {
  const handleClose = () => {
    console.log("closing!");
    setShowModalActivityDelete(false);
  };
  const confirm = () => {
    console.log("Deleting activity!");
    deleteActivity();
    setShowModalActivityDelete(false);
  };

  return (
    <div>
      <Modal
        open={showModalActivityDelete}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classNames(ResponsiveVariables.screenClass, "delete_modal")}
      >
        <div
          className={classNames(ResponsiveVariables.screenClass, "delete_body")}
        >
          <div className={classNames(ResponsiveVariables.screenClass, "title")}>
            Are you sure you want to delete this activity?
          </div>
          <div
            className={classNames(
              ResponsiveVariables.screenClass,
              "answer_buttons"
            )}
          >
            <button
              className={classNames(ResponsiveVariables.screenClass, "confirm")}
              onClick={confirm}
            >
              Delete activity
            </button>
            <button
              className={classNames(ResponsiveVariables.screenClass, "cancel")}
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ActivityDetailDeleteModal;
