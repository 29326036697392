import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./index.css";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import PrivateRoute from "./utils/PrivateRoute";
import Register from "./views/registerPage";
import Verify from "./views/VerifyPage";
import ForgotPassword from "./views/ForgotPasswordPage";
import ResetPassword from "./views/ResetPasswordPage";
import ProtectedPage from "./views/ProtectedPage";
import ActivitiesPage from "./views/activitiesPage";
import UserList from "./views/userList";
import UserDetail from "./views/userDetail";
import ActivityDetailPage from "./views/activityDetailPage";
import Dashboard from "./views/Dashboard";
import Settings from "./views/Settings";
import { useContext } from "react";
import useMediaQuery from "./hooks/useMediaQuery";
import classNames from "classnames";
import AuthContext from "./context/AuthContext";
import Login from "./views/loginPage";
import NavbarMobile from "./components/NavbarMobile";
import Global from "./utils/Global";
import Test from "./views/Test";
import MollieTest from "./views/mollieTest";
import useAxios from "./utils/useAxios";
import ReactGA from "react-ga4";

/* setDefaultBreakpoints([
  { xsmall: 0 }, // all mobile devices
  { small: 576 }, // mobile devices (not sure which one's this big)
  { medium: 768 }, // ipad, ipad pro, ipad mini, etc
  { large: 992 }, // smaller laptops
  { xlarge: 1200 }, // laptops and desktops
]); */

function App() {
  // Google analytics
  ReactGA.initialize("G-1R50RBXZPJ");

  const isMobile = useMediaQuery(768);
  const isTabletQuery = useMediaQuery(1024);
  // const isDesktopQuery = useMediaQuery(1400);
  const isTablet = !isMobile && isTabletQuery;
  const isDesktop = !isMobile && !isTablet;
  // const isBigDesktop = !isMobile && !isTablet && !isDesktop;
  const screenClass = classNames({
    mobile: isMobile,
    tablet: isTablet,
    desktop: isDesktop,
    // bigdesktop: isBigDesktop,
  });
  Global.screenClass = screenClass;
  Global.isMobile = isMobile;
  Global.isTablet = isTablet;
  Global.isDesktop = isDesktop;
  // Global.isBigDesktop = isBigDesktop;

  const auth = useContext(AuthContext);
  console.log(auth);
  const isAuthenticated = auth.authTokens;

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  const api = useAxios();
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(true);

  const faviconUpdate = async () => {
    //grab favicon element by ID
    const favicon = document.getElementById("favicon");
    const logo = document.getElementById("apple-touch-icon");
    const manifest = document.getElementById("manifest");

    //get favicon from backend
    var logo_address = "";
    try {
      const response = await fetch(
        window._env_.REACT_APP_API_URL + "/api/settings/logo/",
        {
          method: "GET",
        }
      );
      const data = await response.json();
      console.log(data);
      logo_address = window._env_.REACT_APP_API_URL + data;
      favicon.href = window._env_.REACT_APP_API_URL + data;
      logo.href = window._env_.REACT_APP_API_URL + data;
      console.log(favicon);
      console.log(logo);
    } catch {
      console.log("Something went wrong while retreiving favicon");
    }

    var organization_name = "";
    try {
      const response = await fetch(
        window._env_.REACT_APP_API_URL + "/api/settings/",
        {
          method: "GET",
        }
      );
      const data = await response.json();
      console.log(data);
      organization_name = data.organization_name;
    } catch {
      console.log("Something went wrong while retreiving favicon");
    }

    document.title = organization_name;
    var myDynamicManifest = {
      short_name: organization_name,
      name: organization_name,
      icons: [
        {
          src: logo_address,
          sizes: "64x64 32x32 24x24 16x16",
          type: "image/x-icon",
        },
        {
          src: logo_address,
          type: "image/png",
          sizes: "192x192",
        },
        {
          src: logo_address,
          type: "image/png",
          sizes: "512x512",
        },
      ],
      start_url: "",
      display: "standalone",
      theme_color: "#000000",
      background_color: "#ffffff",
    };

    //update manifest
    const stringManifest = JSON.stringify(myDynamicManifest);
    console.log(stringManifest);
    manifest.href = encodeURIComponent(stringManifest);
    console.log(manifest);
  };

  const fetchIsOnboardingComplete = async () => {
    try {
      const response = await api.get("/profile/id/");
      console.log(response);
      setIsOnboardingComplete(response.data.is_onboarding_complete);
      console.log(isOnboardingComplete);
    } catch {
      console.log(
        "Something went wrong while checking if onboarding is completed"
      );
    }
  };

  useEffect(() => {
    faviconUpdate();
    fetchIsOnboardingComplete();
  }, [isAuthenticated]);

  return (
    // Wrap your components with React Socks to prevent unnecessary render in different viewports.
    // Just wrap your top level component with BreakpointProvider and use the Breakpoint component anywhere you need.
    // <BreakpointProvider>
    <div>
      <Routes>
        <Route
          path="/mollie"
          element={
            <PrivateRoute
              isOnboardingComplete={isOnboardingComplete}
              setIsOnboardingComplete={setIsOnboardingComplete}
            >
              <div className={classNames(screenClass, "navandbody")}>
                <Header />
                {!isMobile && (
                  <div className={classNames(screenClass, "nav-container")}>
                    <Navbar />
                  </div>
                )}
                <div className={classNames(screenClass, "body-flex-div")}>
                  <MollieTest />
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute
              isOnboardingComplete={isOnboardingComplete}
              setIsOnboardingComplete={setIsOnboardingComplete}
            >
              <div className={classNames(screenClass, "navandbody")}>
                <Header />
                {!isMobile && (
                  <div className={classNames(screenClass, "nav-container")}>
                    <Navbar />
                  </div>
                )}
                <div className={classNames(screenClass, "body-flex-div")}>
                  <Dashboard />
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/protected"
          element={
            <PrivateRoute
              isOnboardingComplete={isOnboardingComplete}
              setIsOnboardingComplete={setIsOnboardingComplete}
            >
              <div className={classNames(screenClass, "navandbody")}>
                <Header />
                {!isMobile && (
                  <div className={classNames(screenClass, "nav-container")}>
                    <Navbar />
                  </div>
                )}
                <div className={classNames(screenClass, "body-flex-div")}>
                  <ProtectedPage />
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/activities"
          element={
            <PrivateRoute
              isOnboardingComplete={isOnboardingComplete}
              setIsOnboardingComplete={setIsOnboardingComplete}
            >
              <div className={classNames(screenClass, "navandbody")}>
                <Header />
                {!isMobile && (
                  <div className={classNames(screenClass, "nav-container")}>
                    <Navbar />
                  </div>
                )}
                <div className={classNames(screenClass, "body-flex-div")}>
                  <ActivitiesPage />
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/activities2"
          element={
            <PrivateRoute
              isOnboardingComplete={isOnboardingComplete}
              setIsOnboardingComplete={setIsOnboardingComplete}
            >
              <div className={classNames(screenClass, "navandbody")}>
                <Header />
                {!isMobile && (
                  <div className={classNames(screenClass, "nav-container")}>
                    <Navbar />
                  </div>
                )}
                <div className={classNames(screenClass, "body-flex-div")}>
                  <ActivitiesPage />
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/activityDetail/:id"
          element={
            <PrivateRoute
              isOnboardingComplete={isOnboardingComplete}
              setIsOnboardingComplete={setIsOnboardingComplete}
            >
              <div className={classNames(screenClass, "navandbody")}>
                <Header />
                {!isMobile && (
                  <div className={classNames(screenClass, "nav-container")}>
                    <Navbar />
                  </div>
                )}
                <div className={classNames(screenClass, "body-flex-div")}>
                  <ActivityDetailPage />
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute
              isOnboardingComplete={isOnboardingComplete}
              setIsOnboardingComplete={setIsOnboardingComplete}
            >
              <div className={classNames(screenClass, "navandbody")}>
                <Header />
                {!isMobile && (
                  <div className={classNames(screenClass, "nav-container")}>
                    <Navbar />
                  </div>
                )}
                <div className={classNames(screenClass, "body-flex-div")}>
                  <UserList />
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/user/:id"
          element={
            <PrivateRoute
              isOnboardingComplete={isOnboardingComplete}
              setIsOnboardingComplete={setIsOnboardingComplete}
            >
              <div className={classNames(screenClass, "navandbody")}>
                <Header />
                {!isMobile && (
                  <div className={classNames(screenClass, "nav-container")}>
                    <Navbar />
                  </div>
                )}
                <div className={classNames(screenClass, "body-flex-div")}>
                  <UserDetail />
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute
              isOnboardingComplete={isOnboardingComplete}
              setIsOnboardingComplete={setIsOnboardingComplete}
            >
              <div className={classNames(screenClass, "navandbody")}>
                <Header />
                {!isMobile && (
                  <div className={classNames(screenClass, "nav-container")}>
                    <Navbar />
                  </div>
                )}
                <div className={classNames(screenClass, "body-flex-div")}>
                  <Settings />
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/test"
          element={
            <PrivateRoute
              isOnboardingComplete={isOnboardingComplete}
              setIsOnboardingComplete={setIsOnboardingComplete}
            >
              <div className={classNames(screenClass, "navandbody")}>
                <Header />
                {!isMobile && (
                  <div className={classNames(screenClass, "nav-container")}>
                    <Navbar />
                  </div>
                )}
                <div className={classNames(screenClass, "body-flex-div")}>
                  <Test />
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <div>
              {isAuthenticated && (
                <PrivateRoute
                  isOnboardingComplete={isOnboardingComplete}
                  setIsOnboardingComplete={setIsOnboardingComplete}
                >
                  <div className={classNames(screenClass, "navandbody")}>
                    <Header />
                    {!isMobile && (
                      <div className={classNames(screenClass, "nav-container")}>
                        <Navbar />
                      </div>
                    )}
                    <div className={classNames(screenClass, "body-flex-div")}>
                      <Dashboard />
                    </div>
                  </div>
                </PrivateRoute>
              )}
              {!isAuthenticated && (
                <div className={classNames(screenClass, "loginbody")}>
                  <Login />
                </div>
              )}
            </div>
          }
        />

        <Route
          path="/register"
          element={
            <div className={classNames(screenClass, "loginbody")}>
              <Register />
            </div>
          }
        />
        <Route
          path="/verify/:uid/:token"
          element={
            <div className={classNames(screenClass, "loginbody")}>
              <Verify />
            </div>
          }
        />
        <Route
          path="/forgotpassword"
          element={
            <div className={classNames(screenClass, "loginbody")}>
              <ForgotPassword />
            </div>
          }
        />
        <Route
          path="/resetpassword/:uid/:token"
          element={
            <div className={classNames(screenClass, "loginbody")}>
              <ResetPassword />
            </div>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
