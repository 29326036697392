import React, { useEffect, useState } from "react";
import useAxios from "../utils/useAxios";
import { useNavigate } from "react-router-dom";
import "./userList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faUserPen } from "@fortawesome/free-solid-svg-icons";
import AddUserModal from "../components/AddUserModal";
import ReactGA from "react-ga4";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";

const userIcon = (
  <FontAwesomeIcon icon={faUser} size="1x" style={{ color: "#2f96ff" }} />
);
const adminUserIcon = (
  <FontAwesomeIcon icon={faUserPen} size="1x" style={{ color: "#2f96ff" }} />
);

const UserList = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/users",
    title: "users",
  });

  const navigate = useNavigate();
  const [res, setRes] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [areMemberProfilesVisible, setAreMemberProfilesVisible] =
    useState(false);
  const [showModalRegister, setShowModalRegister] = useState(false);

  const api = useAxios();
  const handleOpen = () => {
    //invite matis.deschutter@mac.com
    console.log("test!");
    const inviteUser = async () => {
      try {
        const response = await api.get("/invite/");
        console.log("TEST");
        console.log(response.data);
      } catch {
        console.log("Something went wrong while inviting user");
      }
    };
    inviteUser();
  };

  const openModalRegister = () => {
    setShowModalRegister((showModalRegister) => !showModalRegister);
  };

  const fetchData = async () => {
    try {
      const response = await api.get("/users/");
      console.log("TEST");
      console.log(response.data);
      setRes(response.data);
    } catch {
      console.log("Something went wrong while retreiving users");
    }
  };

  const fetchID = async () => {
    try {
      const response = await api.get("/profile/id/");
      setIsAdmin(response.data.admin);
    } catch {
      console.log("Something went wrong while retreiving id");
    }
  };

  const fetchMemberProfilesVisible = async () => {
    try {
      const response = await api.get("/settings/");
      setAreMemberProfilesVisible(response.data.member_profiles_visible);
    } catch {
      console.log(
        "Something went wrong while retreiving member_profiles_visible"
      );
    }
  };

  useEffect(() => {
    fetchData();
    fetchID();
    fetchMemberProfilesVisible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames(ResponsiveVariables.screenClass, "userlist")}>
      {isAdmin ? (
        <div
          className={classNames(
            ResponsiveVariables.screenClass,
            "popup-add-user"
          )}
        >
          <button
            onClick={openModalRegister}
            className="adduserbutton"
            type="button"
          >
            + Invite user(s)
          </button>
          <AddUserModal
            showModalRegister={showModalRegister}
            setShowModalRegister={setShowModalRegister}
          />
        </div>
      ) : (
        ""
      )}

      {/* <h1>User list </h1> */}

      <div
        className={classNames(
          ResponsiveVariables.screenClass,
          "responsive-table"
        )}
      >
        <div
          className={classNames(
            ResponsiveVariables.screenClass,
            "table-header"
          )}
        >
          <div
            className={classNames(ResponsiveVariables.screenClass, "col col-2")}
          >
            First name
          </div>
          <div
            className={classNames(ResponsiveVariables.screenClass, "col col-3")}
          >
            Last name
          </div>
          <div
            className={classNames(ResponsiveVariables.screenClass, "col col-4")}
          >
            E-mail
          </div>
          <div
            className={classNames(ResponsiveVariables.screenClass, "col col-5")}
          >
            Role
          </div>
        </div>
        {Array.isArray(res)
          ? res
              .sort((user1, user2) =>
                user1.name + user1.surname > user2.name + user2.surname ? 1 : -1
              )
              .map((user) => (
                <div
                  onClick={() =>
                    isAdmin || areMemberProfilesVisible
                      ? navigate("/user/" + user.user)
                      : null
                  }
                  // onClick={() => navigate("/user/" + user.user)}
                  className={classNames(
                    ResponsiveVariables.screenClass,
                    "table-row"
                  )}
                  key={user.user}
                >
                  {/* <div className="col col-1" data-label="User">
                                                        {user.user}
                                                      </div> */}

                  {ResponsiveVariables.isMobile ||
                  ResponsiveVariables.isTablet ? (
                    <div
                      className={classNames(
                        ResponsiveVariables.screenClass,
                        "mobcol mobcol-2"
                      )}
                      data-label="firstname"
                    >
                      {user.name + " " + user.surname + " "}
                      {user.admin ? adminUserIcon : userIcon}
                    </div>
                  ) : (
                    <div
                      className={classNames(
                        ResponsiveVariables.screenClass,
                        "col col-2"
                      )}
                      data-label="firstname"
                    >
                      {user.name}
                    </div>
                  )}

                  <div
                    className={classNames(
                      ResponsiveVariables.screenClass,
                      "col col-3"
                    )}
                    data-label="lastname"
                  >
                    {user.surname}
                  </div>
                  {ResponsiveVariables.isMobile ||
                  ResponsiveVariables.isTablet ? (
                    <div
                      className={classNames(
                        ResponsiveVariables.screenClass,
                        "mobcol mobcol-4"
                      )}
                      data-label="email"
                    >
                      {user.email}
                    </div>
                  ) : (
                    <div
                      className={classNames(
                        ResponsiveVariables.screenClass,
                        "col col-4"
                      )}
                      data-label="email"
                    >
                      {user.email}
                    </div>
                  )}
                  {/* <div
                    className={classNames(
                      ResponsiveVariables.screenClass,
                      "col col-4"
                    )}
                    data-label="email"
                  >
                    {user.email}
                  </div> */}
                  <div
                    className={classNames(
                      ResponsiveVariables.screenClass,
                      "col col-5"
                    )}
                    data-label="membericon"
                  >
                    {user.admin ? adminUserIcon : userIcon}
                    {"   "}
                    {user.admin ? "Admin" : "Member "}
                  </div>
                  {res.at(-1) === user ? "" : <hr></hr>}
                </div>
              ))
          : null}
      </div>
    </div>
  );
};

export default UserList;
