import dashboard_b from "../img/dashboard-5462949-black.png";
import activities_b from "../img/calendar-803890-black.png";
import users_b from "../img/users-4626655-black.png";
import profile_b from "../img/user-5469787-black.png";
import dashboard_w from "../img/dashboard-5462949-white.png";
import activities_w from "../img/calendar-803890-white.png";
import users_w from "../img/users-4626655-white.png";
import profile_w from "../img/user-5469787-white.png";
import settings_b from "../img/setting-5479080-black.png";
import settings_w from "../img/setting-5479080-white.png";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";

export const navigationItems = {
  sidebar: [
    {
      name: "Dashboard",
      to: "/Dashboard",
      url_list: ["/Dashboard", "/Dashboard"],
      text: "dashboard",
      photo: dashboard_b,
      photoSelected: dashboard_w,
      access_level: "member",
    },
    {
      name: "Activities",
      to: "/activities",
      url_list: ["/activities", "/activityDetail"],
      text: "activities",
      photo: activities_b,
      photoSelected: activities_w,
      access_level: "member",
    },
    {
      name: "Users",
      to: "/Users",
      url_list: ["/Users", "/user"],
      text: "users",
      photo: users_b,
      photoSelected: users_w,
      access_level: "member",
    },
    {
      name: "Settings",
      to: "/Settings",
      url_list: ["/Settings"],
      text: "settings",
      photo: settings_b,
      photoSelected: settings_w,
      access_level: "admin",
    },
  ],
};
