import "./OnboardingFlow.css";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import useAxios from "../utils/useAxios";

// import for responsive design
import classNames from "classnames";
// globale varibele voor responsive design
import ResponsiveVariables from "../utils/Global";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ClientLogo from "../components/systemGeneralComponents/clientLogo.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import dayjs from "dayjs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const backIcon = <FontAwesomeIcon icon={faChevronLeft} className="back-icon" />;

const OnboardingFlow = ({ setIsOnboardingComplete }) => {
  const navigate = useNavigate();
  const api = useAxios();
  const [id, setId] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    tel: "",
    street: "",
    streetNumber: "",
    zipCode: "",
    place: "",
    dateofBirth: dayjs(new Date("2000-01-01")).format("YYYY-MM-DD"),
    language: "",
    function: "",
    is_onboarding_complete: "False",
  });

  const fetchData = async () => {
    try {
      const id = (await api.get("/profile/id/")).data.id;
      setId(id);
      const response = await api.get("/user/" + id);
      const data = response.data.response;
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: data.name,
        surname: data.surname,
        tel: data.tel,
        street: data.street,
        streetNumber: data.streetNumber,
        zipCode: data.zipCode,
        place: data.place,
        dateofBirth: dayjs(new Date(data.dateofBirth)).format("YYYY-MM-DD"),
        function: data.function,
        language: data.language,
      }));
      setValue("firstname", data.name);
      setValue("lastname", data.surname);
      setValue("tel", data.tel);
      setValue("street", data.street);
      setValue("streetNumber", data.streetNumber);
      setValue("zipCode", data.zipCode);
      setValue("place", data.place);
      setValue(
        "dateofBirth",
        dayjs(new Date(data.dateofBirth)).format("YYYY-MM-DD")
      );
      setValue("function", data.function);
      setValue("language", data.language);
    } catch {
      console.log("Something went wrong while retreiving user");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const savePage0 = (data, event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      name: data.firstname,
      surname: data.lastname,
      dateofBirth: dayjs(data.dateofBirth).format("YYYY-MM-DD"),
    }));
    setPageCount(pageCount + 1);
  };

  const savePage1 = (data, event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      tel: data.tel,
      street: data.street,
      streetNumber: data.streetNumber,
      zipCode: data.zipCode,
      place: data.place,
    }));
    setPageCount(pageCount + 1);
  };

  const savePage2 = async (data, event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      function: data.function,
      language: data.language,
      is_onboarding_complete: "True",
    }));
    const adaptedFormData = formData;
    adaptedFormData.function = data.function;
    adaptedFormData.language = data.language;
    adaptedFormData.is_onboarding_complete = "True";
    setPageCount(pageCount + 1);
    console.log("Finalizing onboarding!");
    console.log(adaptedFormData);
    const response = await api.put("/user/" + id + "/", adaptedFormData);
    console.log(response);
    setIsOnboardingComplete(true);
    // navigate("/");
  };

  // Form validation
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  return (
    <div
      className={classNames(
        ResponsiveVariables.screenClass,
        "parentonboarding"
      )}
    >
      <div
        className={classNames(ResponsiveVariables.screenClass, "onboarding")}
      >
        {pageCount > 0 && (
          <button
            className={classNames(
              ResponsiveVariables.screenClass,
              "back-button"
            )}
            onClick={() => setPageCount(pageCount - 1)}
          >
            {backIcon}
          </button>
        )}
        {pageCount === 0 && (
          <>
            <div
              className={classNames(ResponsiveVariables.screenClass, "head")}
            >
              <ClientLogo width="100px" height="100px" display="flex" />
            </div>
            <h1>Welcome to Quorum</h1>
            <p className="subtitle">We'd love to learn more about you.</p>
            <form onSubmit={handleSubmit(savePage0)}>
              <p className="label">Firstname</p>
              <input
                {...register("firstname", {
                  required: "This field is required.",
                })}
                type="text"
                id="firstname"
                name="firstname"
                placeholder="First Name"
                className="text"
              />
              <p className="form-validation-error">
                {errors && errors.firstname && errors.firstname.message}
              </p>
              <p className="label">Lastname</p>
              <input
                {...register("lastname", {
                  required: "This field is required.",
                })}
                type="text"
                id="lastname"
                name="lastname"
                placeholder="Last Name"
                required
                className="text"
              />
              <p className="form-validation-error">
                {errors && errors.lastname && errors.lastname.message}
              </p>
              <div className="date-picker">
                <Controller
                  {...register("dateofBirth", {
                    required: "This field is required",
                  })}
                  control={control}
                  id="dateofBirth"
                  name="dateofBirth"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Date of birth"
                        value={field.value}
                        inputRef={field.ref}
                        onChange={(date) => {
                          field.onChange(date);
                        }}
                        inputFormat="DD-MM-YYYY"
                        ampm={false}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
              <p className="form-validation-error">
                {errors.dateofBirth && errors.dateofBirth.message}
              </p>
              <button type="submit" className="onboarding-button">
                Next
              </button>
            </form>
            <div class="onboarding-dots">
              <div class="onboarding-dot active"></div>
              <div class="onboarding-dot"></div>
              <div class="onboarding-dot"></div>
            </div>
          </>
        )}
        {pageCount === 1 && (
          <>
            <div
              className={classNames(ResponsiveVariables.screenClass, "head")}
            >
              <ClientLogo width="100px" height="100px" display="flex" />
            </div>
            <h1>We need some more info</h1>
            <p className="subtitle">Your club requires your contact details.</p>
            <form onSubmit={handleSubmit(savePage1)}>
              <div className="pair">
                <div className="input-and-error">
                  <p className="label">Street</p>
                  <input
                    {...register("street", {
                      required: "This field is required",
                      minLength: {
                        value: 2,
                        message: "Min length needs to be 2",
                      },
                      maxLength: { value: 64, message: "Max length is 64!" },
                    })}
                    className="text"
                    id="street"
                    placeholder="Street"
                    type="text"
                  />
                  <p className="form-validation-error">
                    {errors.street && errors.street.message}
                  </p>
                </div>
                <div className="input-and-error">
                  <p className="label">Number</p>
                  <input
                    {...register("streetNumber", {
                      required: "This field is required",
                      minLength: {
                        value: 1,
                        message: "Min length needs to be 1",
                      },
                      maxLength: { value: 10, message: "Max length is 10!" },
                    })}
                    className="text"
                    id="streetNumber"
                    name="streetNumber"
                    placeholder="Streetnumber"
                    type="text"
                  />
                  <p className="form-validation-error">
                    {errors.streetNumber && errors.streetNumber.message}
                  </p>
                </div>
              </div>
              <div className="pair">
                <div className="input-and-error">
                  <p className="label">City</p>
                  <input
                    {...register("place", {
                      required: "This field is required",
                      minLength: {
                        value: 2,
                        message: "Min length needs to be 2",
                      },
                      maxLength: { value: 64, message: "Max length is 64!" },
                    })}
                    className="text"
                    id="place"
                    name="place"
                    placeholder="City"
                    type="text"
                  />
                  <p className="form-validation-error">
                    {errors.place && errors.place.message}
                  </p>
                </div>
                <div className="input-and-error">
                  <p className="label">Zip code</p>
                  <input
                    {...register("zipCode", {
                      required: "This field is required",
                      minLength: {
                        value: 2,
                        message: "Min length needs to be 2",
                      },
                      maxLength: { value: 16, message: "Max length is 16!" },
                    })}
                    className="text"
                    id="zipCode"
                    name="zipCode"
                    placeholder="Postal code"
                    type="text"
                  />
                  <p className="form-validation-error">
                    {errors.zipCode && errors.zipCode.message}
                  </p>
                </div>
              </div>
              <div className="input-and-error">
                <p className="label">Telephone nr.</p>
                <input
                  {...register("tel", {
                    required: "This field is required",
                    minLength: {
                      value: 2,
                      message: "Min length needs to be 2!",
                    },
                    maxLength: { value: 20, message: "Max length is 20!" },
                    pattern: {
                      value:
                        /^[+]?[(]?([0-9]\s*){3}[)]?[-\s.]?[0-9]{3}[-\s.]?([0-9]\s*){4,6}$/,
                      message: "Phone number is not correct",
                      value: /^\+/,
                      message: "Your phone number should start with a +",
                    },
                  })}
                  className="text"
                  id="tel"
                  name="tel"
                  placeholder="Tel (+32 471 47 28 37)"
                  type="text"
                />
                <p className="form-validation-error">
                  {errors.tel && errors.tel.message}
                </p>
              </div>
              <button type="submit" className="onboarding-button">
                Next
              </button>
            </form>
            <div class="onboarding-dots">
              <div class="onboarding-dot" onClick={() => setPageCount(0)}></div>
              <div class="onboarding-dot active"></div>
              <div class="onboarding-dot"></div>
            </div>
          </>
        )}
        {pageCount === 2 && (
          <>
            <div
              className={classNames(ResponsiveVariables.screenClass, "head")}
            >
              <ClientLogo width="100px" height="100px" display="flex" />
            </div>
            <h1>You're almost there</h1>
            <p className="subtitle">We'd love to learn more about you</p>
            <form onSubmit={handleSubmit(savePage2)}>
              <p className="label">Profession</p>
              <input
                {...register("function", {
                  required: "This field is required",
                  minLength: {
                    value: 2,
                    message: "Min length needs to be 2",
                  },
                  maxLength: { value: 64, message: "Max length is 64!" },
                })}
                className="text"
                id="function"
                name="function"
                placeholder="Profession"
                type="text"
              />
              <p className="form-validation-error">
                {errors.profession && errors.profession.message}
              </p>
              <div className="pair language">
                <p className="lable">Language:</p>
                <select
                  {...register("language", {
                    required: "This field is required",
                    minLength: {
                      value: 2,
                      message: "Min length needs to be 2",
                    },
                    maxLength: { value: 32, message: "Max length is 32!" },
                  })}
                  className="select"
                  id="language"
                  name="language"
                  placeholder="Language"
                >
                  <option value="nl">NL</option>
                  <option value="en">ENG</option>
                  <option value="fr">FR</option>
                </select>
              </div>
              <p className="form-validation-error">
                {errors.language && errors.language.message}
              </p>
              <button type="submit" className="onboarding-button">
                Finish
              </button>
            </form>
            <div class="onboarding-dots">
              <div class="onboarding-dot" onClick={() => setPageCount(0)}></div>
              <div class="onboarding-dot" onClick={() => setPageCount(1)}></div>
              <div class="onboarding-dot active"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OnboardingFlow;
