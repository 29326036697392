import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Routes,
  Redirect,
  Link,
} from "react-router-dom";
import useMediaQuery from "../hooks/useMediaQuery";
import classNames from "classnames";
import NavbarMobile from "./NavbarMobile";
import ClientLogo from "./systemGeneralComponents/clientLogo.js";

const Header = () => {
  const isMobile = useMediaQuery(768);
  const isTabletQuery = useMediaQuery(1024);
  const isDesktopQuery = useMediaQuery(1400);
  const isTablet = !isMobile && isTabletQuery;
  const isDesktop = !isMobile && !isTablet && isDesktopQuery;
  // const isBigDesktop = !isMobile && !isTablet && !isDesktop;
  const screenClass = classNames({
    mobile: isMobile,
    tablet: isTablet,
    desktop: isDesktop,
    // bigdesktop: isBigDesktop,
  });

  if (!isMobile) {
    return (
      <div className={classNames(screenClass, "title-bar")}>
        <Link to="/" className="nav-item-tilte-bar logo">
          <ClientLogo width="40px" height="40px" display="flex" />
          <p>QUORUM</p>
        </Link>

        {/* <button className="homebutton" onClick={handleClick}>{<Link to="/" className="nav-item-tilte-bar"><img src={profile} alt="Profile" className="profile-menu-icon" /></Link>}</button> */}
        {/* <Link to="/Dashboard" className="profile"><img src={profile} alt="Profile" className="profile-menu-icon" /></Link> */}
        {/* <Link to="/" className="nav-item-tilte-bar">QUORUM</Link> */}
      </div>
    );
  } else {
    return (
      <div>
        <NavbarMobile />
      </div>
    );
  }
};

export default Header;
