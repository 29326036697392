import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const baseURL = window._env_.REACT_APP_API_URL + "/api";

const useAxios = () => {
  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);

  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${authTokens?.access}` },
  });

  const navigate = useNavigate();

  axiosInstance.interceptors.request.use(async (req) => {
    try {
      const user = jwt_decode(authTokens.access);
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
      console.log(isExpired);
      if (!isExpired) return req;
      const refresh = jwt_decode(authTokens.refresh);
      const refreshIsExpired = dayjs.unix(refresh.exp).diff(dayjs()) < 1;
      if (refreshIsExpired) {
        localStorage.removeItem("authTokens");
        setAuthTokens(null);
        navigate("/");
      }
    } catch {
      return req;
    }

    const response = await axios.post(`${baseURL}/token/refresh/`, {
      refresh: authTokens.refresh,
    });

    localStorage.setItem("authTokens", JSON.stringify(response.data));

    setAuthTokens(response.data);
    setUser(jwt_decode(response.data.access));

    req.headers.Authorization = `Bearer ${response.data.access}`;
    return req;
  });

  return axiosInstance;
};

export default useAxios;
